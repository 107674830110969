import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function TransferIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2C18.5977 2 19.9037 3.24892 19.9949 4.82373L20 5V21C20 21.7607 19.1926 22.229 18.5417 21.8892L18.4453 21.8321L16.127 20.287L14.7071 21.7071C14.3466 22.0676 13.7794 22.0953 13.3871 21.7903L13.2929 21.7071L12 20.415L10.7071 21.7071C10.3466 22.0676 9.77939 22.0953 9.3871 21.7903L9.29289 21.7071L7.872 20.287L5.53609 21.8443L5.43977 21.8984L5.33726 21.9417L5.22929 21.9736L5.11662 21.9933L5 22L4.88338 21.9933L4.77071 21.9736L4.66274 21.9417L4.59486 21.9144L4.49646 21.8642L4.46391 21.8443L4.37455 21.7803L4.29289 21.7071L4.21969 21.6254L4.15569 21.5361L4.10164 21.4398L4.06274 21.3493L4.04448 21.2958L4.02641 21.2293L4.00673 21.1166L4 21V5C4 3.40232 5.24892 2.09634 6.82373 2.00509L7 2H17ZM17 4H7C6.48716 4 6.06449 4.38604 6.00673 4.88338L6 5V19.13L7.4453 18.1679C7.80587 17.9276 8.27511 17.9489 8.61069 18.208L8.70711 18.2929L10 19.585L11.2929 18.2929C11.6534 17.9324 12.2206 17.9047 12.6129 18.2097L12.7071 18.2929L14 19.585L15.2929 18.2929C15.5993 17.9865 16.0636 17.9154 16.4435 18.1037L16.5547 18.1679L18 19.131V5C18 4.48716 17.614 4.06449 17.1166 4.00673L17 4ZM11.7071 12.7071C12.0976 12.3166 12.0976 11.6834 11.7071 11.2929L11.415 11H13L13.1166 11.0067C13.614 11.0645 14 11.4872 14 12V14L14.0067 14.1166C14.0645 14.614 14.4872 15 15 15C15.5523 15 16 14.5523 16 14V12L15.9949 11.8237C15.9037 10.2489 14.5977 9 13 9H11.414L11.7071 8.70711L11.7903 8.6129C12.0953 8.22061 12.0676 7.65338 11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289L8.29289 9.29289L8.21677 9.37747C7.99846 9.64793 7.95309 9.9812 8.03835 10.275L8.04372 10.2931L8.08075 10.3943L8.1556 10.5366C8.20469 10.6147 8.26443 10.6864 8.33368 10.7489L10.2929 12.7071L10.3871 12.7903C10.7794 13.0953 11.3466 13.0676 11.7071 12.7071Z"
        fill="#7F8B9B"
      />
    </SvgIcon>
  );
}
