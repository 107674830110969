import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function BankTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 10H5.5V12V15V16C4.39543 16 3.5 16.8954 3.5 18V19C3.5 20.1046 4.39543 21 5.5 21H19.5C20.6046 21 21.5 20.1046 21.5 19V18C21.5 16.8954 20.6046 16 19.5 16V15V12V10H17.5V12V15V16H15.5V15V12V10H13.5V12V15V16H11.5V15V12V10H9.5V12V15V16H7.5V15V12V10Z"
        fill="currentColor"
      />
      <path
        d="M3.5 7.42337C3.5 6.57133 4.0398 5.81284 4.84483 5.53373L11.8448 3.1067C12.2692 2.95955 12.7308 2.95955 13.1552 3.1067L20.1552 5.53373C20.9602 5.81284 21.5 6.57133 21.5 7.42337V8C21.5 9.10457 20.6046 10 19.5 10H5.5C4.39543 10 3.5 9.10457 3.5 8V7.42337Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
