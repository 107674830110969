import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CalendarSearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29892 4.1665H12.7011C13.3719 4.16649 13.9255 4.16648 14.3765 4.20333C14.8449 4.2416 15.2755 4.32374 15.68 4.52982C16.3072 4.84939 16.8171 5.35933 17.1367 5.98654C17.3428 6.39098 17.4249 6.82157 17.4632 7.28998C17.5 7.74097 17.5 8.2946 17.5 8.9654V12.7009C17.5 13.3717 17.5 13.9254 17.4632 14.3764C17.4249 14.8448 17.3428 15.2754 17.1367 15.6798C16.8171 16.307 16.3072 16.8169 15.68 17.1365C15.2755 17.3426 14.8449 17.4247 14.3765 17.463C13.9255 17.4999 13.3719 17.4998 12.7011 17.4998H7.29894C6.62812 17.4998 6.07448 17.4999 5.62348 17.463C5.15506 17.4247 4.72448 17.3426 4.32003 17.1365C3.69283 16.8169 3.18289 16.307 2.86331 15.6798C2.65724 15.2754 2.5751 14.8448 2.53683 14.3764C2.49998 13.9254 2.49999 13.3717 2.5 12.7009V8.96542C2.49999 8.29461 2.49998 7.74098 2.53683 7.28998C2.5751 6.82157 2.65724 6.39098 2.86331 5.98654C3.18289 5.35933 3.69283 4.84939 4.32003 4.52982C4.72448 4.32374 5.15506 4.2416 5.62348 4.20333C6.07448 4.16648 6.62811 4.16649 7.29892 4.1665ZM5.7592 5.86446C5.39385 5.89431 5.20702 5.94842 5.07668 6.01483C4.76308 6.17462 4.50811 6.42958 4.34832 6.74319C4.28192 6.87352 4.22781 7.06036 4.19796 7.4257C4.16732 7.80078 4.16667 8.28602 4.16667 8.99984V12.6665C4.16667 13.3803 4.16732 13.8656 4.19796 14.2406C4.22781 14.606 4.28192 14.7928 4.34832 14.9232C4.50811 15.2368 4.76308 15.4917 5.07668 15.6515C5.20702 15.7179 5.39385 15.772 5.7592 15.8019C6.13427 15.8325 6.61952 15.8332 7.33333 15.8332H12.6667C13.3805 15.8332 13.8657 15.8325 14.2408 15.8019C14.6061 15.772 14.793 15.7179 14.9233 15.6515C15.2369 15.4917 15.4919 15.2368 15.6517 14.9232C15.7181 14.7928 15.7722 14.606 15.802 14.2406C15.8327 13.8656 15.8333 13.3803 15.8333 12.6665V8.99984C15.8333 8.28602 15.8327 7.80078 15.802 7.4257C15.7722 7.06036 15.7181 6.87352 15.6517 6.74319C15.4919 6.42958 15.2369 6.17462 14.9233 6.01483C14.793 5.94842 14.6061 5.89431 14.2408 5.86446C13.8657 5.83382 13.3805 5.83317 12.6667 5.83317H7.33333C6.61952 5.83317 6.13427 5.83382 5.7592 5.86446Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58341 9.16667C8.89306 9.16667 8.33341 9.72631 8.33341 10.4167C8.33341 11.107 8.89306 11.6667 9.58341 11.6667C10.2738 11.6667 10.8334 11.107 10.8334 10.4167C10.8334 9.72631 10.2738 9.16667 9.58341 9.16667ZM6.66675 10.4167C6.66675 8.80584 7.97258 7.5 9.58341 7.5C11.1942 7.5 12.5001 8.80584 12.5001 10.4167C12.5001 10.919 12.3731 11.3916 12.1495 11.8042L13.0893 12.7441C13.4148 13.0695 13.4148 13.5972 13.0893 13.9226C12.7639 14.248 12.2363 14.248 11.9108 13.9226L10.971 12.9828C10.5584 13.2064 10.0857 13.3333 9.58341 13.3333C7.97258 13.3333 6.66675 12.0275 6.66675 10.4167Z"
        fill="currentColor"
      />
      <path
        d="M7.49992 3.33333C7.49992 2.8731 7.12682 2.5 6.66659 2.5C6.20635 2.5 5.83325 2.8731 5.83325 3.33333V5C5.83325 5.46024 6.20635 5.83333 6.66659 5.83333C7.12682 5.83333 7.49992 5.46024 7.49992 5V3.33333Z"
        fill="currentColor"
      />
      <path
        d="M14.1666 3.33333C14.1666 2.8731 13.7935 2.5 13.3333 2.5C12.873 2.5 12.4999 2.8731 12.4999 3.33333V5C12.4999 5.46024 12.873 5.83333 13.3333 5.83333C13.7935 5.83333 14.1666 5.46024 14.1666 5V3.33333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
