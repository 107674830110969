import { SvgIcon } from "@mui/material";
import { type ComponentProps } from "react";

export function MoneyIconOutlined(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M10.4163 3.69352C14.0913 3.69352 17.083 6.68519 17.083 10.3602C17.083 14.0352 14.0913 17.0269 10.4163 17.0269C9.37467 17.0269 8.37467 16.7935 7.44967 16.3352C7.01634 16.1185 6.54134 16.0102 6.07467 16.0102C5.82467 16.0102 5.56634 16.0435 5.31634 16.1102L4.44967 16.3435L4.69134 15.4602C4.87467 14.7435 4.79134 13.9852 4.45801 13.3435C3.99134 12.4102 3.75801 11.4102 3.75801 10.3685C3.74967 6.68519 6.74134 3.69352 10.4163 3.69352ZM10.4163 2.02686C5.81634 2.02686 2.08301 5.76019 2.08301 10.3602C2.08301 11.6435 2.38301 12.9185 2.96634 14.0852C3.11634 14.3685 3.15801 14.7185 3.07467 15.0352L2.57467 16.8852C2.52467 17.0685 2.52467 17.2685 2.57467 17.4519C2.62467 17.6269 2.71634 17.7935 2.84967 17.9352C2.98301 18.0685 3.14967 18.1685 3.33301 18.2102C3.42467 18.2352 3.52467 18.2435 3.61634 18.2435C3.70801 18.2435 3.80801 18.2352 3.89967 18.2102L5.74967 17.7102C5.85801 17.6852 5.96634 17.6685 6.07467 17.6685C6.29134 17.6685 6.50801 17.7185 6.70801 17.8185C7.82467 18.3769 9.09134 18.6852 10.4247 18.6852C15.0247 18.6852 18.758 14.9519 18.758 10.3519C18.758 5.75186 15.0163 2.02686 10.4163 2.02686Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4166 5.56006C10.6166 5.56006 10.8166 5.64339 10.9499 5.78506C11.0916 5.92673 11.1749 6.11839 11.1749 6.31839C12.2749 6.60173 13.1916 7.47673 13.1916 8.67673C13.1916 8.87673 13.1083 9.07673 12.9666 9.21006C12.8249 9.35173 12.6333 9.43506 12.4333 9.43506C12.2333 9.43506 12.0333 9.35173 11.8999 9.21006C11.7583 9.06839 11.6749 8.87673 11.6749 8.67673C11.6749 8.28506 11.2499 7.75173 10.4166 7.75173C9.58327 7.75173 9.15827 8.28506 9.15827 8.67673C9.15827 9.06839 9.58327 9.60173 10.4166 9.60173C11.8166 9.60173 13.1916 10.5767 13.1916 12.0434C13.1916 13.2434 12.2749 14.1184 11.1749 14.3851C11.1749 14.6017 11.0916 14.7934 10.9499 14.9351C10.8083 15.0767 10.6166 15.1601 10.4166 15.1601C10.2166 15.1601 10.0166 15.0767 9.88327 14.9351C9.7416 14.7934 9.65827 14.6017 9.65827 14.4017C8.55827 14.1184 7.6416 13.2434 7.6416 12.0434C7.6416 11.8434 7.72493 11.6434 7.8666 11.5101C8.00827 11.3684 8.19993 11.2851 8.39993 11.2851C8.59993 11.2851 8.79993 11.3684 8.93327 11.5101C9.07494 11.6517 9.15827 11.8434 9.15827 12.0434C9.15827 12.4351 9.58327 12.9684 10.4166 12.9684C11.2499 12.9684 11.6749 12.4351 11.6749 12.0434C11.6749 11.6517 11.2499 11.1184 10.4166 11.1184C9.0166 11.1184 7.6416 10.1434 7.6416 8.67673C7.6416 7.47673 8.55827 6.61006 9.65827 6.33506C9.65827 6.11839 9.7416 5.92673 9.88327 5.78506C10.0249 5.64339 10.2166 5.56006 10.4166 5.56006Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
