import { SvgIcon, type SvgIconProps } from "@mui/material";

export function BankCardExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2761 24.3904C30.7968 24.9111 30.7968 25.7554 30.2761 26.2761L27.6095 28.9427C27.0888 29.4634 26.2446 29.4634 25.7239 28.9427C25.2032 28.422 25.2032 27.5778 25.7239 27.0571L26.1144 26.6666L21.3333 26.6666C20.597 26.6666 20 26.0696 20 25.3333C20 24.5969 20.597 23.9999 21.3333 23.9999L26.1144 23.9999L25.7239 23.6094C25.2032 23.0887 25.2032 22.2445 25.7239 21.7238C26.2446 21.2031 27.0888 21.2031 27.6095 21.7238L30.2761 24.3904Z"
        fill="currentColor"
      />
      <path
        d="M3.03091 10.59C3.1766 10.6666 3.36536 10.6666 3.74287 10.6666H28.2568C28.6343 10.6666 28.8231 10.6666 28.9688 10.59C29.0939 10.5241 29.2009 10.4124 29.2613 10.2845C29.3316 10.1357 29.3238 9.95462 29.3082 9.59251C29.2684 8.66861 29.1651 8.04303 28.8972 7.51729C28.5137 6.76464 27.9018 6.15272 27.1491 5.76923C26.2935 5.33325 25.1734 5.33325 22.9332 5.33325H9.0665C6.82629 5.33325 5.70619 5.33325 4.85054 5.76923C4.09789 6.15272 3.48597 6.76464 3.10248 7.51729C2.8346 8.04303 2.73132 8.66861 2.69149 9.59251C2.67589 9.95462 2.66808 10.1357 2.73836 10.2845C2.79873 10.4124 2.90574 10.5241 3.03091 10.59Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73917 13.6973C2.6665 13.8399 2.6665 14.0265 2.6665 14.3999V20.2666C2.6665 22.5068 2.6665 23.6269 3.10248 24.4825C3.48597 25.2352 4.09789 25.8471 4.85054 26.2306C5.70619 26.6666 6.82629 26.6666 9.0665 26.6666H16.2665C16.6399 26.6666 16.8266 26.6666 16.9692 26.5939C17.0946 26.53 17.1966 26.428 17.2605 26.3026C17.3332 26.16 17.3332 25.9733 17.3332 25.5999V25.3333C17.3332 20.915 20.9149 17.3333 25.3332 17.3333H28.2665C28.6399 17.3333 28.8266 17.3333 28.9692 17.2606C29.0946 17.1967 29.1966 17.0947 29.2605 16.9692C29.3332 16.8266 29.3332 16.64 29.3332 16.2666V14.3999C29.3332 14.0265 29.3332 13.8399 29.2605 13.6973C29.1966 13.5718 29.0946 13.4698 28.9692 13.4059C28.8266 13.3333 28.6399 13.3333 28.2665 13.3333H3.73317C3.3598 13.3333 3.17312 13.3333 3.03051 13.4059C2.90507 13.4698 2.80308 13.5718 2.73917 13.6973ZM7.99984 19.9999C7.99984 19.2635 8.59679 18.6666 9.33317 18.6666H14.6665C15.4029 18.6666 15.9998 19.2635 15.9998 19.9999C15.9998 20.7363 15.4029 21.3333 14.6665 21.3333H9.33317C8.59679 21.3333 7.99984 20.7363 7.99984 19.9999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
