import { SvgIcon, type SvgIconProps } from "@mui/material";

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 16C2.6665 8.63616 8.63604 2.66663 15.9998 2.66663C23.3636 2.66663 29.3332 8.63616 29.3332 16C29.3332 23.3638 23.3636 29.3333 15.9998 29.3333C8.63604 29.3333 2.6665 23.3638 2.6665 16ZM14.6665 10.6666C14.6665 9.93025 15.2635 9.33329 15.9998 9.33329C16.7362 9.33329 17.3332 9.93025 17.3332 10.6666C17.3332 11.403 16.7362 12 15.9998 12C15.2635 12 14.6665 11.403 14.6665 10.6666ZM15.9998 14.6666C16.7362 14.6666 17.3332 15.2636 17.3332 16V21.3333C17.3332 22.0697 16.7362 22.6666 15.9998 22.6666C15.2635 22.6666 14.6665 22.0697 14.6665 21.3333V16C14.6665 15.2636 15.2635 14.6666 15.9998 14.6666Z"
        fill="#3F82F0"
      />
    </SvgIcon>
  );
}
