import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function FileViewTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.25"
        d="M4 6.80049C4 5.12033 4 4.28025 4.32698 3.63852C4.6146 3.07403 5.07354 2.61509 5.63803 2.32747C6.27976 2.00049 7.11984 2.00049 8.8 2.00049H14.6745C15.1637 2.00049 15.4083 2.00049 15.6385 2.05575C15.8425 2.10474 16.0376 2.18555 16.2166 2.29521C16.4184 2.41889 16.5914 2.59184 16.9373 2.93775L19.0627 5.06323C19.4086 5.40913 19.5816 5.58209 19.7053 5.78392C19.8149 5.96286 19.8957 6.15795 19.9447 6.36202C20 6.5922 20 6.83679 20 7.32597V17.2005C20 18.8806 20 19.7207 19.673 20.3625C19.3854 20.9269 18.9265 21.3859 18.362 21.6735C17.7202 22.0005 16.8802 22.0005 15.2 22.0005H8.8C7.11984 22.0005 6.27976 22.0005 5.63803 21.6735C5.07354 21.3859 4.6146 20.9269 4.32698 20.3625C4 19.7207 4 18.8806 4 17.2005V6.80049Z"
        fill="currentColor"
      />
      <path
        d="M14 3.4147C14 2.63365 14.6332 2.00049 15.4142 2.00049C15.7893 2.00049 16.149 2.14949 16.4142 2.4147L19.5858 5.58628C19.851 5.85149 20 6.2112 20 6.58627C20 7.36732 19.3668 8.00049 18.5858 8.00049H16C14.8954 8.00049 14 7.10506 14 6.00049V3.4147Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9999 17.0005C13.8781 17.0005 15.2766 15.3072 15.8561 14.4649C16.0503 14.1828 16.0503 13.8182 15.8561 13.536C15.2766 12.6937 13.8781 11.0005 11.9999 11.0005C10.1217 11.0005 8.72317 12.6937 8.14365 13.536C7.94951 13.8182 7.94951 14.1828 8.14365 14.4649C8.72317 15.3072 10.1217 17.0005 11.9999 17.0005ZM12.0001 15.0005C12.5524 15.0005 13.0001 14.5528 13.0001 14.0005C13.0001 13.4482 12.5524 13.0005 12.0001 13.0005C11.4478 13.0005 11.0001 13.4482 11.0001 14.0005C11.0001 14.5528 11.4478 15.0005 12.0001 15.0005Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
