import { SvgIcon, type SvgIconProps } from "@mui/material";

export function BankCardFavoriteTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        opacity="0.25"
        d="M3.83301 14.6667C3.83301 11.8665 3.83301 10.4664 4.37798 9.3968C4.85734 8.45599 5.62225 7.69108 6.56306 7.21172C7.63261 6.66675 9.03275 6.66675 11.833 6.66675H29.1663C31.9666 6.66675 33.3667 6.66675 34.4363 7.21172C35.3771 7.69108 36.142 8.45599 36.6214 9.3968C37.1663 10.4664 37.1663 11.8665 37.1663 14.6667V25.3334C37.1663 28.1337 37.1663 29.5338 36.6214 30.6034C36.142 31.5442 35.3771 32.3091 34.4363 32.7884C33.3667 33.3334 31.9666 33.3334 29.1663 33.3334H11.833C9.03275 33.3334 7.63261 33.3334 6.56306 32.7884C5.62225 32.3091 4.85734 31.5442 4.37798 30.6034C3.83301 29.5338 3.83301 28.1337 3.83301 25.3334V14.6667Z"
        fill="currentColor"
      />
      <path
        d="M31.397 25.517C31.6817 24.8326 32.6512 24.8326 32.9359 25.517L34.2515 28.6802C34.3708 28.9669 34.6396 29.1636 34.949 29.1904L38.0732 29.4612C38.7886 29.5232 39.0958 30.4007 38.5753 30.8955L36.202 33.1513C35.9867 33.356 35.8966 33.6597 35.9655 33.9487L36.7663 37.3058C36.9383 38.0269 36.1539 38.5967 35.5214 38.2104L32.6008 36.4265C32.3342 36.2636 31.9988 36.2636 31.7321 36.4265L28.8116 38.2104C28.179 38.5967 27.3946 38.0269 27.5666 37.3058L28.3676 33.9476C28.4364 33.6593 28.3468 33.356 28.1324 33.1514L25.767 30.8943C25.2481 30.3992 25.5556 29.5234 26.2701 29.4612L29.3841 29.1905C29.6934 29.1636 29.9621 28.967 30.0813 28.6803L31.397 25.517Z"
        fill="currentColor"
      />
      <path
        d="M10.5 24.9999C10.5 24.0794 11.2462 23.3333 12.1667 23.3333H18.8333C19.7538 23.3333 20.5 24.0794 20.5 24.9999C20.5 25.9204 19.7538 26.6666 18.8333 26.6666H12.1667C11.2462 26.6666 10.5 25.9204 10.5 24.9999Z"
        fill="currentColor"
      />
      <path d="M3.83301 13.3333H37.1663V16.6666H3.83301V13.3333Z" fill="currentColor" />
    </SvgIcon>
  );
}
