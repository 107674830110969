import { SvgIcon, type SvgIconProps } from "@mui/material";

export function ClipboardListIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2.62988H11C9.69378 2.62988 8.58254 3.46469 8.17071 4.62988H7L6.82373 4.63498C5.24892 4.72622 4 6.0322 4 7.62988V19.6299L4.00509 19.8062C4.09634 21.381 5.40232 22.6299 7 22.6299H17L17.1763 22.6248C18.7511 22.5335 20 21.2276 20 19.6299V7.62988L19.9949 7.45361C19.9037 5.8788 18.5977 4.62988 17 4.62988H15.8293C15.4175 3.46469 14.3062 2.62988 13 2.62988ZM15.8293 6.62988H17L17.1166 6.63661C17.614 6.69438 18 7.11705 18 7.62988V19.6299L17.9933 19.7465C17.9355 20.2438 17.5128 20.6299 17 20.6299H7L6.88338 20.6232C6.38604 20.5654 6 20.1427 6 19.6299V7.62988L6.00673 7.51326C6.06449 7.01592 6.48716 6.62988 7 6.62988H8.17071C8.58254 7.79507 9.69378 8.62988 11 8.62988H13C14.3062 8.62988 15.4175 7.79507 15.8293 6.62988ZM10 5.62988C10 6.18217 10.4477 6.62988 11 6.62988H13C13.5523 6.62988 14 6.18217 14 5.62988C14 5.0776 13.5523 4.62988 13 4.62988H11C10.4477 4.62988 10 5.0776 10 5.62988ZM9.01 11.6299C9.56228 11.6299 10.01 12.0776 10.01 12.6299C10.01 13.1427 9.62396 13.5654 9.12662 13.6232L9 13.6299C8.44772 13.6299 8 13.1822 8 12.6299C8 12.117 8.38604 11.6944 8.88338 11.6366L9.01 11.6299ZM16 12.6299C16 12.0776 15.5523 11.6299 15 11.6299H13L12.8834 11.6366C12.386 11.6944 12 12.117 12 12.6299C12 13.1822 12.4477 13.6299 13 13.6299H15L15.1166 13.6232C15.614 13.5654 16 13.1427 16 12.6299ZM9.01 15.6299C9.56228 15.6299 10.01 16.0776 10.01 16.6299C10.01 17.1427 9.62396 17.5654 9.12662 17.6232L9 17.6299C8.44772 17.6299 8 17.1822 8 16.6299C8 16.117 8.38604 15.6944 8.88338 15.6366L9.01 15.6299ZM16 16.6299C16 16.0776 15.5523 15.6299 15 15.6299H13L12.8834 15.6366C12.386 15.6944 12 16.117 12 16.6299C12 17.1822 12.4477 17.6299 13 17.6299H15L15.1166 17.6232C15.614 17.5654 16 17.1427 16 16.6299Z"
        fill="#7F8B9B"
      />
    </SvgIcon>
  );
}
