import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function EcommerceReceiptIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        d="M6.6665 5.33331H25.3332V27.3333C25.3332 27.809 24.8492 28.1317 24.4101 27.9487L21.7601 26.8445C21.4836 26.7293 21.1763 26.7106 20.8878 26.7914L17.0784 27.858C16.3729 28.0555 15.6268 28.0555 14.9213 27.858L11.1119 26.7914C10.8234 26.7106 10.5161 26.7293 10.2396 26.8445L7.58958 27.9487C7.15046 28.1317 6.6665 27.809 6.6665 27.3333V5.33331Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 12C11.2635 12 10.6665 12.597 10.6665 13.3333C10.6665 14.0697 11.2635 14.6667 11.9998 14.6667H14.6665C15.4029 14.6667 15.9998 14.0697 15.9998 13.3333C15.9998 12.597 15.4029 12 14.6665 12H11.9998ZM19.9998 12C19.2635 12 18.6665 12.597 18.6665 13.3333C18.6665 14.0697 19.2635 14.6667 19.9998 14.6667C20.7362 14.6667 21.3332 14.0697 21.3332 13.3333C21.3332 12.597 20.7362 12 19.9998 12ZM10.6665 18.6667C10.6665 17.9303 11.2635 17.3333 11.9998 17.3333H14.6665C15.4029 17.3333 15.9998 17.9303 15.9998 18.6667C15.9998 19.403 15.4029 20 14.6665 20H11.9998C11.2635 20 10.6665 19.403 10.6665 18.6667ZM19.9998 17.3333C19.2635 17.3333 18.6665 17.9303 18.6665 18.6667C18.6665 19.403 19.2635 20 19.9998 20C20.7362 20 21.3332 19.403 21.3332 18.6667C21.3332 17.9303 20.7362 17.3333 19.9998 17.3333Z"
        fill="currentColor"
      />
      <path
        d="M4 5.33333C4 4.59695 4.59695 4 5.33333 4H26.6667C27.403 4 28 4.59695 28 5.33333C28 6.06971 27.403 6.66667 26.6667 6.66667H5.33333C4.59695 6.66667 4 6.06971 4 5.33333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
