import { SvgIcon, type SvgIconProps } from "@mui/material";

export function SparkleIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.145 19.7119C23.032 19.5623 22.8813 19.4313 22.6553 19.3752L21.5063 19.0011C21.2238 18.9076 20.9601 18.7393 20.7529 18.5335C20.5457 18.3278 20.3762 18.066 20.282 17.7854L19.9053 16.6258C19.8487 16.4387 19.7169 16.2891 19.5662 16.1769C19.246 15.9524 18.8128 15.9524 18.4926 16.1769C18.3419 16.2891 18.21 16.4387 18.1535 16.6258L17.7768 17.7854C17.6826 18.066 17.532 18.3278 17.3248 18.5335C17.1176 18.7393 16.8539 18.9076 16.5902 19.0011L15.4224 19.3752C15.234 19.4313 15.0833 19.5623 14.9703 19.7119C14.8573 19.8615 14.8008 20.0485 14.8008 20.2543C14.8008 20.46 14.8573 20.6284 14.9703 20.7967C15.0833 20.9463 15.234 21.0773 15.4224 21.1334L16.5902 21.5074C16.8727 21.601 17.1364 21.7693 17.3436 21.975C17.5508 22.1808 17.7203 22.4426 17.8145 22.7232L18.1912 23.9015C18.2666 24.0886 18.3796 24.2382 18.5303 24.3317C18.6809 24.4439 18.8693 24.5 19.0765 24.5C19.2837 24.5 19.4532 24.4439 19.6039 24.3317C19.7546 24.2195 19.8864 24.0699 19.9429 23.8828L20.3196 22.7419C20.4138 22.4613 20.5645 22.1995 20.7905 21.9937C20.9977 21.788 21.2614 21.6197 21.544 21.5261L22.7118 21.1521C22.9001 21.096 23.0508 20.965 23.1638 20.8154C23.2768 20.6658 23.3334 20.4787 23.3334 20.273C23.3334 20.0673 23.2768 19.8989 23.1638 19.7306L23.145 19.7119Z"
        fill="currentColor"
      />
      <path
        d="M4.91149 10.0603C4.7608 10.2848 4.66663 10.5466 4.66663 10.8085C4.66663 11.0703 4.74197 11.3322 4.91149 11.5566C5.06218 11.7811 5.2882 11.9494 5.5519 12.0429L7.68034 12.735C8.26425 12.922 8.79165 13.2587 9.20603 13.6701C9.63925 14.1003 9.95946 14.624 10.1478 15.2039L10.8447 17.3361C10.9389 17.5979 11.1084 17.8037 11.3156 17.972C11.5417 18.1216 11.8054 18.2152 12.0691 18.2152C12.3328 18.2152 12.6153 18.1403 12.8225 17.972C13.0485 17.8224 13.1992 17.5979 13.2934 17.3361L13.9903 15.2226C14.1787 14.6614 14.5177 14.119 14.9321 13.7076C15.3653 13.2774 15.8927 12.9594 16.4578 12.7724L18.6051 12.0803C18.8688 11.9868 19.0948 11.8185 19.2455 11.6127C19.3962 11.3883 19.4903 11.1264 19.4903 10.8646C19.4903 10.6027 19.415 10.3222 19.2455 10.1164C19.0948 9.892 18.8688 9.72366 18.5485 9.63015L16.4201 8.93811C15.8362 8.75107 15.3088 8.4144 14.8756 8.00292C14.4424 7.57274 14.1222 7.04903 13.9338 6.48792L13.2369 4.3744C13.1427 4.11255 12.992 3.90681 12.766 3.73847C12.3328 3.42051 11.6923 3.42051 11.2403 3.73847C11.0143 3.8881 10.8447 4.11255 10.7694 4.3744L10.0536 6.52533C9.86528 7.08644 9.54508 7.59144 9.11185 8.00292C8.69747 8.4144 8.17007 8.73237 7.62383 8.93811L5.47656 9.63015C5.21286 9.72366 4.98683 9.892 4.83615 10.0977L4.91149 10.0603Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
