import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function SparklesFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.8385 16.896C19.7417 16.7677 19.6125 16.6555 19.4188 16.6074L18.4339 16.2868C18.1917 16.2066 17.9657 16.0623 17.7881 15.886C17.6105 15.7096 17.4652 15.4852 17.3845 15.2447L17.0616 14.2507C17.0132 14.0904 16.9002 13.9622 16.771 13.866C16.4965 13.6736 16.1252 13.6736 15.8507 13.866C15.7216 13.9622 15.6086 14.0904 15.5601 14.2507L15.2372 15.2447C15.1565 15.4852 15.0274 15.7096 14.8498 15.886C14.6722 16.0623 14.4461 16.2066 14.2201 16.2868L13.2191 16.6074C13.0577 16.6555 12.9285 16.7677 12.8316 16.896C12.7348 17.0242 12.6863 17.1845 12.6863 17.3609C12.6863 17.5372 12.7348 17.6815 12.8316 17.8258C12.9285 17.9541 13.0577 18.0663 13.2191 18.1144L14.2201 18.435C14.4623 18.5152 14.6883 18.6595 14.8659 18.8358C15.0435 19.0122 15.1888 19.2366 15.2695 19.4771L15.5924 20.4871C15.657 20.6474 15.7539 20.7757 15.883 20.8558C16.0122 20.952 16.1736 21.0001 16.3512 21.0001C16.5288 21.0001 16.6741 20.952 16.8033 20.8558C16.9324 20.7596 17.0455 20.6314 17.0939 20.4711L17.4168 19.4931C17.4975 19.2526 17.6267 19.0282 17.8204 18.8518C17.998 18.6755 18.224 18.5312 18.4662 18.451L19.4672 18.1304C19.6286 18.0823 19.7578 17.9701 19.8547 17.8418C19.9515 17.7136 20 17.5533 20 17.3769C20 17.2006 19.9515 17.0563 19.8547 16.912L19.8385 16.896Z"
        fill="currentColor"
      />
      <path
        d="M4.20988 8.62314C4.08072 8.81552 4 9.03997 4 9.26441C4 9.48886 4.06458 9.7133 4.20988 9.90568C4.33904 10.0981 4.53278 10.2424 4.75881 10.3225L6.58318 10.9157C7.08368 11.076 7.53573 11.3646 7.89092 11.7173C8.26225 12.086 8.53672 12.5349 8.69816 13.0319L9.29553 14.8595C9.37625 15.0839 9.52156 15.2603 9.69915 15.4046C9.89289 15.5328 10.1189 15.613 10.3449 15.613C10.571 15.613 10.8131 15.5489 10.9907 15.4046C11.1845 15.2763 11.3136 15.0839 11.3944 14.8595L11.9917 13.0479C12.1532 12.567 12.4438 12.102 12.799 11.7493C13.1703 11.3806 13.6224 11.1081 14.1067 10.9477L15.9472 10.3546C16.1732 10.2744 16.367 10.1301 16.4961 9.95378C16.6253 9.7614 16.706 9.53695 16.706 9.31251C16.706 9.08806 16.6415 8.84759 16.4961 8.67124C16.367 8.47886 16.1733 8.33457 15.8988 8.25441L14.0744 7.66124C13.5739 7.50092 13.1219 7.21235 12.7505 6.85965C12.3792 6.49092 12.1047 6.04203 11.9433 5.56107L11.3459 3.74949C11.2652 3.52504 11.136 3.34869 10.9423 3.20441C10.571 2.93187 10.022 2.93187 9.63457 3.20441C9.44083 3.33266 9.29553 3.52504 9.23095 3.74949L8.61744 5.59314C8.45599 6.07409 8.18153 6.50695 7.8102 6.85965C7.45501 7.21235 7.00295 7.48489 6.53475 7.66124L4.69423 8.25441C4.4682 8.33457 4.27446 8.47886 4.1453 8.65521L4.20988 8.62314Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
