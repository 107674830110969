import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function WishtenderIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.85936 22.3601L20.186 22.1597C20.3157 22.1578 20.4399 22.137 20.5583 22.0992C20.6134 21.9523 20.6442 21.7949 20.6442 21.63L20.6665 10.1186C20.6681 9.39037 20.0841 8.80993 19.3633 8.82283L7.0366 9.02324C6.90689 9.02514 6.78269 9.04584 6.66388 9.08415C6.60927 9.23066 6.57837 9.38807 6.57797 9.55288L6.55567 21.0623C6.55417 21.7906 7.13821 22.371 7.85936 22.3601Z"
        fill="#FFDF58"
      />
      <path
        d="M21.2166 23C21.2068 23 21.1969 23 21.1871 22.9998H21.1859L8.07897 22.8068C7.86406 22.8044 7.6492 22.7692 7.44309 22.7021C6.52856 22.4094 5.88789 21.5238 5.88857 20.5496L5.88623 8.1346C5.88623 7.52825 6.12131 6.96317 6.54819 6.54317C6.96634 6.13181 7.51863 5.91231 8.10422 5.92003L21.2083 6.11306C22.116 6.12556 22.9407 6.71618 23.2598 7.58275C23.3535 7.83931 23.4007 8.10349 23.4007 8.36997L23.401 20.7834C23.401 21.3886 23.1665 21.9532 22.7408 22.3733C22.3305 22.7781 21.7902 23 21.2166 23ZM21.2091 21.3712C21.3577 21.3733 21.4921 21.3172 21.5967 21.2139C21.7098 21.1022 21.7721 20.9494 21.7721 20.7834L21.7718 8.36997C21.7718 8.29467 21.758 8.21854 21.7306 8.14358C21.6436 7.90733 21.4241 7.74502 21.185 7.7417L8.07897 7.54866C7.9337 7.54608 7.79492 7.60155 7.69051 7.70429C7.57737 7.81558 7.51506 7.96837 7.51506 8.13445L7.51741 20.55C7.51721 20.8245 7.69227 21.0717 7.94317 21.152C7.99654 21.1694 8.04679 21.1775 8.10021 21.1782L21.2091 21.3712Z"
        fill="#14C3F3"
      />
      <path d="M22.5861 15.4401L6.70166 15.2065V13.1863L22.5861 13.4199V15.4401Z" fill="#14C3F3" />
      <path
        d="M13.593 21.9269L13.5918 6.66905L15.6951 6.69995L15.6959 21.9578L13.593 21.9269Z"
        fill="#14C3F3"
      />
      <path
        d="M14.6688 6.67225L11.7822 5.33225L13.4147 3.54072L14.5512 5.75768L15.3173 1.98981L17.7026 3.31611L14.6688 6.67225Z"
        fill="#FFDF58"
      />
      <path
        d="M14.5452 7.12925C14.4829 7.12925 14.42 7.11597 14.3609 7.08847L10.5059 5.2985C10.3795 5.2398 10.2885 5.12441 10.261 4.98778C10.2336 4.8511 10.2728 4.70954 10.3666 4.6065L12.5466 2.21414C12.6432 2.10802 12.7855 2.05602 12.928 2.07501C13.0703 2.09401 13.1943 2.18152 13.2598 2.30926L14.208 4.15862L14.9823 0.35057C15.0101 0.213744 15.1014 0.0984517 15.2283 0.0400494C15.355 -0.0181579 15.502 -0.0127375 15.6241 0.0551385L18.8099 1.82636C18.9266 1.89121 19.0073 2.00577 19.0291 2.13742C19.0509 2.26912 19.0115 2.4036 18.9219 2.50264L14.8701 6.985C14.7851 7.079 14.6662 7.12925 14.5452 7.12925ZM11.4156 4.75544L14.4369 6.15833L17.903 2.32401L15.7212 1.11103L14.8173 5.55696C14.7803 5.73925 14.6321 5.87818 14.4479 5.90352C14.2635 5.92862 14.0835 5.83501 13.9986 5.66952L12.7686 3.27056L11.4156 4.75544Z"
        fill="#14C3F3"
      />
      <path
        d="M11.0941 18.1234H1.21059C0.857486 18.1234 0.571185 17.8371 0.571185 17.484C0.571185 17.1309 0.857486 16.8446 1.21059 16.8446H11.0941C11.4472 16.8446 11.7335 17.1309 11.7335 17.484C11.7335 17.8371 11.4472 18.1234 11.0941 18.1234ZM4.19128 14.9528H0.639403C0.2863 14.9528 0 14.6665 0 14.3134C0 13.9603 0.2863 13.674 0.639403 13.674H4.19128C4.54438 13.674 4.83068 13.9603 4.83068 14.3134C4.83068 14.6665 4.54438 14.9528 4.19128 14.9528ZM9.73041 12.1698H3.62175C3.26865 12.1698 2.98235 11.8835 2.98235 11.5304C2.98235 11.1773 3.26865 10.891 3.62175 10.891H9.73041C10.0835 10.891 10.3698 11.1773 10.3698 11.5304C10.3698 11.8835 10.0835 12.1698 9.73041 12.1698Z"
        fill="#14C3F3"
      />
    </SvgIcon>
  );
}
