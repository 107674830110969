import { SvgIcon, type SvgIconProps } from "@mui/material";

export function InfoCircleFillRevertedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3335 14C2.3335 7.55666 7.55684 2.33331 14.0002 2.33331C20.4435 2.33331 25.6668 7.55666 25.6668 14C25.6668 20.4433 20.4435 25.6666 14.0002 25.6666C7.55684 25.6666 2.3335 20.4433 2.3335 14ZM14.0002 8.16665C14.6445 8.16665 15.1668 8.68898 15.1668 9.33331V14C15.1668 14.6443 14.6445 15.1666 14.0002 15.1666C13.3558 15.1666 12.8335 14.6443 12.8335 14V9.33331C12.8335 8.68898 13.3558 8.16665 14.0002 8.16665ZM12.8335 18.6666C12.8335 18.0223 13.3558 17.5 14.0002 17.5C14.6445 17.5 15.1668 18.0223 15.1668 18.6666C15.1668 19.311 14.6445 19.8333 14.0002 19.8333C13.3558 19.8333 12.8335 19.311 12.8335 18.6666Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
