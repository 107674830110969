import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CheckCircleTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" {...props}>
      <path
        opacity="0.25"
        d="M20.0002 3.33325C10.7954 3.33325 3.3335 10.7952 3.3335 19.9999C3.3335 29.2047 10.7954 36.6666 20.0002 36.6666C29.2049 36.6666 36.6668 29.2047 36.6668 19.9999C36.6668 10.7952 29.2049 3.33325 20.0002 3.33325Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.1787 15.4882C26.8295 16.139 26.8295 17.1943 26.1787 17.8452L19.512 24.5118C18.8611 25.1627 17.8059 25.1627 17.155 24.5118L13.8217 21.1785C13.1708 20.5276 13.1708 19.4724 13.8217 18.8215C14.4725 18.1706 15.5278 18.1706 16.1787 18.8215L18.3335 20.9763L23.8217 15.4882C24.4725 14.8373 25.5278 14.8373 26.1787 15.4882Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
