import { SvgIcon, type SvgIconProps } from "@mui/material";

export function GiftTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        d="M3.33325 10.8333C3.33325 10.3731 3.70635 10 4.16659 10H15.8333C16.2935 10 16.6666 10.3731 16.6666 10.8333V15C16.6666 16.3807 15.5473 17.5 14.1666 17.5H5.83325C4.45254 17.5 3.33325 16.3807 3.33325 15V10.8333Z"
        fill="currentColor"
      />
      <path
        d="M2.5 7.49992C2.5 6.57944 3.24619 5.83325 4.16667 5.83325H15.8333C16.7538 5.83325 17.5 6.57944 17.5 7.49992V8.33325C17.5 9.25373 16.7538 9.99992 15.8333 9.99992H4.16667C3.24619 9.99992 2.5 9.25373 2.5 8.33325V7.49992Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83325 5C5.83325 3.61929 6.95254 2.5 8.33325 2.5C8.97355 2.5 9.55762 2.74071 9.99992 3.13658C10.4422 2.74071 11.0263 2.5 11.6666 2.5C13.0473 2.5 14.1666 3.61929 14.1666 5C14.1666 6.38071 13.0473 7.5 11.6666 7.5H10.8333V17.5H9.16659V7.5H8.33325C6.95254 7.5 5.83325 6.38071 5.83325 5ZM9.16659 5.83333H8.33325C7.87301 5.83333 7.49992 5.46024 7.49992 5C7.49992 4.53976 7.87301 4.16667 8.33325 4.16667C8.79349 4.16667 9.16659 4.53976 9.16659 5V5.83333ZM10.8333 5.83333H11.6666C12.1268 5.83333 12.4999 5.46024 12.4999 5C12.4999 4.53976 12.1268 4.16667 11.6666 4.16667C11.2063 4.16667 10.8333 4.53976 10.8333 5V5.83333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
