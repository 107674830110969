import { SvgIcon, type SvgIconProps } from "@mui/material";

export function RequestWasMeantToGifterIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="40"
      viewBox="0 0 38 40"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9979 0C8.85137 0 0.616516 8.23485 0.616516 18.3814C0.616516 21.2305 1.27825 24.0244 2.56494 26.5978C2.87742 27.2228 2.98771 27.9948 2.8039 28.6933L1.70102 32.7924C1.59073 33.1967 1.59073 33.6195 1.70102 34.0239C1.79292 34.4283 2.0135 34.7959 2.3076 35.09C2.6017 35.3841 2.96933 35.6047 3.37372 35.715C3.77811 35.8069 4.21926 35.8069 4.62365 35.715L8.70432 34.6121C9.40281 34.4283 10.1564 34.5202 10.7998 34.8511C13.2813 36.0826 16.0569 36.7627 18.9979 36.7627C29.1444 36.7627 37.3792 28.5463 37.3792 18.3814C37.3792 8.21647 29.1444 0 18.9979 0ZM18.9979 16.727C22.086 16.727 25.1189 18.8777 25.1189 22.1312C25.1189 24.7781 23.0969 26.7081 20.6706 27.2963C20.6706 27.7559 20.4868 28.197 20.1743 28.4911C19.8802 28.8036 19.439 28.9874 18.9979 28.9874C18.5567 28.9874 18.1156 28.8036 17.8215 28.4911C17.509 28.197 17.3252 27.7559 17.3252 27.3147C14.8988 26.6714 12.8769 24.7597 12.8769 22.1128C12.8769 21.6532 13.0607 21.2305 13.3548 20.918C13.6673 20.6055 14.0901 20.4217 14.5496 20.4217C15.0091 20.4217 15.4319 20.6055 15.726 20.918C16.0385 21.2305 16.2223 21.6532 16.2223 22.1128C16.2223 22.9583 17.1597 24.1531 18.9979 24.1531C20.836 24.1531 21.7735 22.9583 21.7735 22.1128C21.7735 21.2672 20.836 20.0541 18.9979 20.0541C15.9098 20.0541 12.8769 17.9218 12.8769 14.6683C12.8769 12.0214 14.8988 10.1098 17.3252 9.50317C17.3252 9.02525 17.509 8.60248 17.8215 8.29C18.1156 7.99589 18.5567 7.81208 18.9979 7.81208C19.439 7.81208 19.8802 7.99589 20.1743 8.29C20.4868 8.60248 20.6706 9.02525 20.6706 9.48478C23.0969 10.1097 25.1189 12.0398 25.1189 14.6867C25.1189 15.1462 24.9351 15.569 24.641 15.8815C24.3285 16.1756 23.9057 16.3594 23.4462 16.3594C22.9866 16.3594 22.5639 16.1756 22.2698 15.8815C21.9573 15.569 21.7735 15.1462 21.7735 14.6867C21.7735 13.8412 20.836 12.6464 18.9979 12.6464C17.1597 12.6464 16.2223 13.8412 16.2223 14.6867C16.2223 15.5323 17.1597 16.727 18.9979 16.727Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1209 22.1316C25.1209 24.7785 23.0989 26.7085 20.6726 27.2968C20.6726 27.7563 20.4888 28.1974 20.1763 28.4915C19.8822 28.804 19.4411 28.9878 18.9999 28.9878C18.5587 28.9878 18.1176 28.804 17.8235 28.4915C17.511 28.1974 17.3272 27.7563 17.3272 27.3151C14.9009 26.6718 12.8789 24.7601 12.8789 22.1132C12.8789 21.6537 13.0627 21.2309 13.3568 20.9184C13.6693 20.6059 14.0921 20.4221 14.5516 20.4221C15.0111 20.4221 15.4339 20.6059 15.728 20.9184C16.0405 21.2309 16.2243 21.6537 16.2243 22.1132C16.2243 22.9587 17.1618 24.1535 18.9999 24.1535C20.838 24.1535 21.7755 22.9587 21.7755 22.1132C21.7755 21.2677 20.838 20.0545 18.9999 20.0545C15.9118 20.0545 12.8789 17.9223 12.8789 14.6688C12.8789 12.0218 14.9009 10.1102 17.3272 9.50359C17.3272 9.02567 17.511 8.6029 17.8235 8.29042C18.1176 7.99631 18.5587 7.8125 18.9999 7.8125C19.4411 7.8125 19.8822 7.99631 20.1763 8.29042C20.4888 8.6029 20.6726 9.02567 20.6726 9.4852C23.0989 10.1102 25.1209 12.0402 25.1209 14.6871C25.1209 15.1467 24.9371 15.5694 24.643 15.8819C24.3305 16.176 23.9077 16.3598 23.4482 16.3598C22.9887 16.3598 22.5659 16.176 22.2718 15.8819C21.9593 15.5694 21.7755 15.1467 21.7755 14.6871C21.7755 13.8416 20.838 12.6468 18.9999 12.6468C17.1618 12.6468 16.2243 13.8416 16.2243 14.6871C16.2243 15.5327 17.1618 16.7275 18.9999 16.7275C22.088 16.7275 25.1209 18.8781 25.1209 22.1316Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8459 33.193C37.5627 33.9098 37.5627 35.0679 36.8459 35.7847L33.1696 39.461C32.4527 40.1779 31.2947 40.1779 30.5778 39.461C29.8609 38.7441 29.8609 37.5861 30.5778 36.8692L31.1109 36.3362H24.512C23.501 36.3362 22.6738 35.509 22.6738 34.498C22.6738 33.4871 23.501 32.6599 24.512 32.6599H31.1109L30.5778 32.1268C29.8609 31.41 29.8609 30.2519 30.5778 29.5351C31.2947 28.8182 32.4527 28.8182 33.1696 29.5351L36.8459 33.2113V33.193Z"
        fill="#0CB4E9"
      />
    </SvgIcon>
  );
}
