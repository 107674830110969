import { SvgIcon } from "@mui/material";
import type { ComponentProps } from "react";

export function DinersClubCardIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="34" height="23" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="34" height="23" rx="3.5" stroke="#E5E7EB" />
      <path
        d="M19.045 19.4999C22.9425 19.5187 26.5 16.2921 26.5 12.367C26.5 8.07493 22.9425 5.10783 19.045 5.10944H15.6906C11.7467 5.10783 8.5 8.07573 8.5 12.367C8.5 16.2929 11.7467 19.5187 15.6906 19.4999H19.045Z"
        fill="#0079BE"
      />
      <path
        d="M15.7067 5.70422C12.1023 5.70543 9.18211 8.65329 9.18091 12.2917C9.18211 15.9296 12.1023 18.8775 15.7067 18.8787C19.3114 18.8775 22.2328 15.9296 22.2332 12.2917C22.2328 8.65329 19.3118 5.70543 15.7067 5.70422ZM11.5704 12.2917C11.5697 11.4504 11.8227 10.6286 12.2965 9.93342C12.7702 9.23829 13.4427 8.70225 14.2259 8.39535V16.1876C13.4426 15.8809 12.7701 15.3449 12.2963 14.6499C11.8225 13.9548 11.5695 13.1329 11.5704 12.2917ZM17.187 16.1892V8.39495C17.9708 8.70141 18.6439 9.23741 19.118 9.93277C19.5921 10.6281 19.8452 11.4505 19.8441 12.2921C19.845 13.1336 19.5918 13.9559 19.1178 14.6512C18.6437 15.3465 17.9707 15.8826 17.187 16.1892Z"
        fill="white"
      />
      <path
        d="M19.045 19.4999C22.9425 19.5187 26.5 16.2921 26.5 12.367C26.5 8.07493 22.9425 5.10783 19.045 5.10944H15.6906C11.7467 5.10783 8.5 8.07573 8.5 12.367C8.5 16.2929 11.7467 19.5187 15.6906 19.4999H19.045Z"
        fill="#0079BE"
      />
      <path
        d="M15.7067 5.70422C12.1023 5.70543 9.18211 8.65329 9.18091 12.2917C9.18211 15.9296 12.1023 18.8775 15.7067 18.8787C19.3114 18.8775 22.2328 15.9296 22.2332 12.2917C22.2328 8.65329 19.3118 5.70543 15.7067 5.70422ZM11.5704 12.2917C11.5697 11.4504 11.8227 10.6286 12.2965 9.93342C12.7702 9.23829 13.4427 8.70225 14.2259 8.39535V16.1876C13.4426 15.8809 12.7701 15.3449 12.2963 14.6499C11.8225 13.9548 11.5695 13.1329 11.5704 12.2917ZM17.187 16.1892V8.39495C17.9708 8.70141 18.6439 9.23741 19.118 9.93277C19.5921 10.6281 19.8452 11.4505 19.8441 12.2921C19.845 13.1336 19.5918 13.9559 19.1178 14.6512C18.6437 15.3465 17.9707 15.8826 17.187 16.1892Z"
        fill="white"
      />
    </SvgIcon>
  );
}
