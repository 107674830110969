import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function DeletedStatementDescriptorIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3251 3.49219H4.66512C4.01512 3.49219 3.49512 4.01219 3.49512 4.66219C3.49512 5.31219 4.01512 5.83219 4.66512 5.83219H23.3351C23.9751 5.83219 24.5051 5.31219 24.5051 4.66219C24.5051 4.01219 23.9851 3.49219 23.3351 3.49219H23.3251Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.985 19.4524C23.375 19.0624 23.375 18.4224 22.985 18.0324C22.755 17.8024 22.445 17.7124 22.155 17.7524C21.935 17.7724 21.725 17.8724 21.565 18.0324L20.275 19.3224L18.985 18.0324C18.875 17.9224 18.755 17.8524 18.615 17.8024C18.265 17.6724 17.845 17.7524 17.565 18.0324C17.395 18.2024 17.305 18.4124 17.285 18.6424C17.245 18.9324 17.345 19.2324 17.565 19.4524L18.855 20.7424L17.565 22.0324C17.175 22.4224 17.175 23.0624 17.565 23.4524C17.615 23.5024 17.665 23.5424 17.715 23.5824H17.725C18.115 23.8324 18.635 23.7924 18.975 23.4624H18.985L20.275 22.1524L21.565 23.4524C21.725 23.6124 21.935 23.7124 22.155 23.7324C22.445 23.7724 22.755 23.6824 22.985 23.4524C23.375 23.0624 23.375 22.4224 22.985 22.0324L22.155 21.2124L21.685 20.7424L22.155 20.2724L22.985 19.4524Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0752 14.7524C20.1452 14.7524 20.2052 14.7524 20.2652 14.7524H21.6252C21.9152 14.7524 22.1552 14.5124 22.1552 14.2224V9.33236C22.1552 8.69236 21.6352 8.17236 20.9852 8.17236H6.9852C6.3452 8.17236 5.8252 8.69236 5.8252 9.33236V23.9324C5.8252 24.3424 6.2452 24.6224 6.6252 24.4624L8.9452 23.4924C9.1952 23.3924 9.4652 23.3724 9.7252 23.4424C9.7252 23.4424 11.3552 23.9124 11.3652 23.9124C11.9152 24.0724 12.5052 24.2224 13.0952 24.2224C13.5552 24.2324 14.3652 24.1724 14.6752 23.7724C14.7552 23.7024 14.7952 23.6024 14.8152 23.5024C14.8452 23.3424 14.7552 23.1524 14.7052 23.0124C14.1052 21.3124 14.1252 19.3924 14.9752 17.7724C15.6752 16.4224 16.9052 15.3924 18.3852 15.0024C18.9652 14.8524 19.5352 14.7724 20.0752 14.7624V14.7524ZM12.8252 18.6624H10.4852C9.8452 18.6624 9.3252 18.1424 9.3252 17.4924C9.3252 16.8424 9.8452 16.3224 10.4852 16.3224H12.8252C13.4652 16.3224 13.9852 16.8524 13.9852 17.4924C13.9852 18.1324 13.4652 18.6624 12.8252 18.6624ZM12.8252 13.9924H10.4852C9.8452 13.9924 9.3252 13.4724 9.3252 12.8224C9.3252 12.1724 9.8452 11.6624 10.4852 11.6624H12.8252C13.4652 11.6624 13.9852 12.1824 13.9852 12.8224C13.9852 13.4624 13.4652 13.9924 12.8252 13.9924ZM17.4852 13.9924C16.8452 13.9924 16.3252 13.4724 16.3252 12.8224C16.3252 12.1724 16.8452 11.6624 17.4852 11.6624C18.1252 11.6624 18.6552 12.1824 18.6552 12.8224C18.6552 13.4624 18.1352 13.9924 17.4852 13.9924Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
