import { SvgIcon } from "@mui/material";
import type { ComponentProps } from "react";

export function UserAvatarIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 108 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_220_8363)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4775 87.7C15.4775 74.4111 26.2553 63.6333 39.5442 63.6333H68.4331C81.722 63.6333 92.4998 74.4111 92.4998 87.7C92.4998 95.6778 86.0331 102.144 78.0553 102.144H29.922C21.9442 102.144 15.4775 95.6778 15.4775 87.7Z"
          fill="#F9FAFC"
        />
        <path
          opacity="0.8"
          d="M53.9804 10.3C40.4895 10.3 29.5571 21.2323 29.5571 34.7444C29.5571 48.2565 40.4895 59.1889 54.0016 59.1889C67.5137 59.1889 78.446 48.2565 78.446 34.7444C78.446 21.2323 67.4925 10.3 53.9804 10.3Z"
          fill="#EDEFF5"
        />
        <g filter="url(#filter0_dd_220_8363)">
          <path
            d="M65.9213 19.9557C65.9213 19.9576 65.9208 19.9594 65.9197 19.961C65.6505 20.3658 65.4775 20.8474 65.4775 21.3098C65.4775 21.773 65.6126 22.2556 65.9213 22.6609C66.1915 23.0663 66.5967 23.3751 67.0791 23.5489L70.8996 24.8035C71.9416 25.151 72.8871 25.7494 73.6396 26.5022C74.4115 27.2743 74.9903 28.2201 75.3376 29.2818L76.5919 33.1423C76.7655 33.6249 77.0743 33.9916 77.4409 34.3005C77.8461 34.5707 78.3285 34.7444 78.7916 34.7444C79.2547 34.7444 79.7757 34.6093 80.1423 34.3005C80.5475 34.0302 80.8176 33.6249 80.9913 33.1423L82.2455 29.3204C82.5928 28.2973 83.191 27.3322 83.9435 26.5794C84.7154 25.8073 85.6609 25.2282 86.6835 24.8808L90.5427 23.6261C91.0058 23.4524 91.4303 23.1435 91.7004 22.7768C91.9705 22.3714 92.1442 21.8888 92.1442 21.4256C92.1442 20.9623 92.0091 20.4411 91.7004 20.0744C91.4303 19.669 91.0251 19.3602 90.4462 19.1865L86.6256 17.9318C85.5837 17.5843 84.6382 16.986 83.8471 16.2331C83.0752 15.461 82.4964 14.5152 82.149 13.4922L80.8948 9.67023C80.7211 9.18766 80.451 8.82091 80.0458 8.51207C79.274 7.93299 78.1162 7.93299 77.3058 8.51207C76.9006 8.78231 76.5919 9.18766 76.4568 9.67023L75.164 13.5501C74.8167 14.5731 74.2571 15.4803 73.466 16.2138C72.7134 16.9666 71.7679 17.5264 70.8032 17.9125L66.944 19.1672C66.5192 19.3265 66.1269 19.5995 65.8565 19.9266C65.8398 19.9467 65.8627 19.9728 65.8853 19.9599L65.9071 19.9474C65.9134 19.9438 65.9213 19.9484 65.9213 19.9557Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_220_8363"
          x="56.5886"
          y="3.63331"
          width="44.4443"
          height="44.4445"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.44444" />
          <feGaussianBlur stdDeviation="4.44444" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.14902 0 0 0 0 0.403922 0 0 0 0 1 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_220_8363" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.11111" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.14902 0 0 0 0 0.403922 0 0 0 0 1 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_220_8363"
            result="effect2_dropShadow_220_8363"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_220_8363"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_220_8363">
          <rect
            width="77.0444"
            height="96.2889"
            fill="white"
            transform="translate(15.4771 5.85555)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
