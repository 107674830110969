import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CalendarClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="Date + Time / Calendar Schedule ">
        <path
          id="Shape"
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8385 5.5H7.16146H7.16142C6.63429 5.49998 6.17954 5.49997 5.80497 5.53057C5.40963 5.56287 5.01641 5.63419 4.63803 5.82698C4.07354 6.1146 3.6146 6.57354 3.32698 7.13803C3.13419 7.51641 3.06287 7.90963 3.03057 8.30497C2.99997 8.67954 2.99998 9.13429 3 9.66142V9.66146V17.3385V17.3386C2.99998 17.8657 2.99997 18.3205 3.03057 18.695C3.06287 19.0904 3.13419 19.4836 3.32698 19.862C3.6146 20.4265 4.07354 20.8854 4.63803 21.173C5.01641 21.3658 5.40963 21.4371 5.80497 21.4694C6.17952 21.5 6.63425 21.5 7.16136 21.5H7.16148H16.8385H16.8386C17.3658 21.5 17.8205 21.5 18.195 21.4694C18.5904 21.4371 18.9836 21.3658 19.362 21.173C19.9265 20.8854 20.3854 20.4265 20.673 19.862C20.8658 19.4836 20.9371 19.0904 20.9694 18.695C21 18.3205 21 17.8657 21 17.3386V9.66144C21 9.1343 21 8.67954 20.9694 8.30497C20.9371 7.90963 20.8658 7.51641 20.673 7.13803C20.3854 6.57354 19.9265 6.1146 19.362 5.82698C18.9836 5.63419 18.5904 5.56287 18.195 5.53057C17.8205 5.49997 17.3657 5.49998 16.8386 5.5H16.8385Z"
          fill="#7F8B9B"
        />
        <path
          id="Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 23.5C20.7614 23.5 23 21.2614 23 18.5C23 15.7386 20.7614 13.5 18 13.5C15.2386 13.5 13 15.7386 13 18.5C13 21.2614 15.2386 23.5 18 23.5ZM19 16.5C19 15.9477 18.5523 15.5 18 15.5C17.4477 15.5 17 15.9477 17 16.5V18.5C17 18.7652 17.1054 19.0196 17.2929 19.2071L18.2929 20.2071C18.6834 20.5976 19.3166 20.5976 19.7071 20.2071C20.0976 19.8166 20.0976 19.1834 19.7071 18.7929L19 18.0858V16.5Z"
          fill="#7F8B9B"
        />
        <g id="Shape_3">
          <path
            d="M9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5V6.5C7 7.05228 7.44772 7.5 8 7.5C8.55228 7.5 9 7.05228 9 6.5V4.5Z"
            fill="#7F8B9B"
          />
          <path
            d="M17 4.5C17 3.94772 16.5523 3.5 16 3.5C15.4477 3.5 15 3.94772 15 4.5V6.5C15 7.05228 15.4477 7.5 16 7.5C16.5523 7.5 17 7.05228 17 6.5V4.5Z"
            fill="#7F8B9B"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
