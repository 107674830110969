import { SvgIcon, type SvgIconProps } from "@mui/material";

export function ShoppingListIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="Shopping + Ecommerce / Shopping List">
        <path
          id="Shape"
          d="M14.2413 4C15.0463 3.99999 15.7106 3.99998 16.2518 4.04419C16.8139 4.09012 17.3306 4.18868 17.816 4.43597C18.5686 4.81947 19.1805 5.43139 19.564 6.18404C19.8113 6.66937 19.9099 7.18608 19.9558 7.74817C20 8.28937 20 8.95373 20 9.7587V9.75873V16.2413V16.2413C20 17.0463 20 17.7106 19.9558 18.2518C19.9099 18.8139 19.8113 19.3306 19.564 19.816C19.1805 20.5686 18.5686 21.1805 17.816 21.564C17.3306 21.8113 16.8139 21.9099 16.2518 21.9558C15.7106 22 15.0463 22 14.2413 22H9.75868C8.95372 22 8.28936 22 7.74818 21.9558C7.18608 21.9099 6.66937 21.8113 6.18404 21.564C5.43139 21.1805 4.81947 20.5686 4.43597 19.816C4.18868 19.3306 4.09012 18.8139 4.04419 18.2518C3.99998 17.7106 3.99999 17.0463 4 16.2413V16.2413V9.7587V9.75868C3.99999 8.95372 3.99998 8.28937 4.04419 7.74817C4.09012 7.18608 4.18868 6.66937 4.43597 6.18404C4.81947 5.43139 5.43139 4.81947 6.18404 4.43597C6.66937 4.18868 7.18608 4.09012 7.74817 4.04419C8.28937 3.99998 8.95372 3.99999 9.75868 4H9.7587H10V6H9.8C8.94342 6 8.36113 6.00078 7.91104 6.03755C7.47262 6.07337 7.24842 6.1383 7.09202 6.21799C6.7157 6.40973 6.40973 6.7157 6.21799 7.09202C6.1383 7.24842 6.07337 7.47262 6.03755 7.91104C6.00078 8.36113 6 8.94342 6 9.8V16.2C6 17.0566 6.00078 17.6389 6.03755 18.089C6.07337 18.5274 6.1383 18.7516 6.21799 18.908C6.40973 19.2843 6.7157 19.5903 7.09202 19.782C7.24842 19.8617 7.47262 19.9266 7.91104 19.9624C8.36113 19.9992 8.94342 20 9.8 20H14.2C15.0566 20 15.6389 19.9992 16.089 19.9624C16.5274 19.9266 16.7516 19.8617 16.908 19.782C17.2843 19.5903 17.5903 19.2843 17.782 18.908C17.8617 18.7516 17.9266 18.5274 17.9624 18.089C17.9992 17.6389 18 17.0566 18 16.2V9.8C18 8.94342 17.9992 8.36113 17.9624 7.91104C17.9266 7.47262 17.8617 7.24842 17.782 7.09202C17.5903 6.7157 17.2843 6.40973 16.908 6.21799C16.7516 6.1383 16.5274 6.07337 16.089 6.03755C15.6389 6.00078 15.0566 6 14.2 6H14V4H14.2413H14.2413Z"
          fill="currentColor"
        />
        <path
          id="Shape_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12ZM13 11C12.4477 11 12 11.4477 12 12C12 12.5523 12.4477 13 13 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13ZM13 15C12.4477 15 12 15.4477 12 16C12 16.5523 12.4477 17 13 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H13ZM9 17C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17Z"
          fill="currentColor"
        />
        <path
          id="Shape_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5C16 6.65685 14.6569 8 13 8H11C9.34315 8 8 6.65685 8 5ZM11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H11Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
