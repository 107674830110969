import { SvgIcon } from "@mui/material";
import type { ComponentProps } from "react";

export function UnknownCardIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80727 8C2.52414 8 2.38257 8 2.2733 7.94253C2.17943 7.89316 2.09917 7.80937 2.05389 7.71346C2.00118 7.60182 2.00704 7.46602 2.01874 7.19444C2.04861 6.50152 2.12607 6.03233 2.32698 5.63803C2.6146 5.07354 3.07354 4.6146 3.63803 4.32698C4.27976 4 5.11984 4 6.8 4H17.2C18.8802 4 19.7202 4 20.362 4.32698C20.9265 4.6146 21.3854 5.07354 21.673 5.63803C21.8739 6.03233 21.9514 6.50152 21.9813 7.19444C21.993 7.46602 21.9988 7.60182 21.9461 7.71346C21.9008 7.80937 21.8206 7.89316 21.7267 7.94253C21.6174 8 21.4759 8 21.1927 8H2.80727ZM2 10.8C2 10.52 2 10.38 2.0545 10.273C2.10243 10.1789 2.17892 10.1024 2.273 10.0545C2.37996 10 2.51997 10 2.8 10H21.2C21.48 10 21.62 10 21.727 10.0545C21.8211 10.1024 21.8976 10.1789 21.9455 10.273C22 10.38 22 10.52 22 10.8V15.2C22 16.8802 22 17.7202 21.673 18.362C21.3854 18.9265 20.9265 19.3854 20.362 19.673C19.7202 20 18.8802 20 17.2 20H6.8C5.11984 20 4.27976 20 3.63803 19.673C3.07354 19.3854 2.6146 18.9265 2.32698 18.362C2 17.7202 2 16.8802 2 15.2V10.8ZM7 14C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16H11C11.5523 16 12 15.5523 12 15C12 14.4477 11.5523 14 11 14H7Z"
        fill="#7F8B9B"
      />
    </SvgIcon>
  );
}
