import { SvgIcon, type SvgIconProps } from "@mui/material";

export function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 4C8.24847 4 4.66675 7.58172 4.66675 12C4.66675 16.4183 8.24847 20 12.6667 20C17.085 20 20.6667 16.4183 20.6667 12C20.6667 7.58172 17.085 4 12.6667 4ZM2.66675 12C2.66675 6.47715 7.1439 2 12.6667 2C18.1896 2 22.6667 6.47715 22.6667 12C22.6667 17.5228 18.1896 22 12.6667 22C7.1439 22 2.66675 17.5228 2.66675 12Z"
        fill="#FFB020"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 6C13.219 6 13.6667 6.44772 13.6667 7V11H15.6667C16.219 11 16.6667 11.4477 16.6667 12C16.6667 12.5523 16.219 13 15.6667 13H12.6667C12.1145 13 11.6667 12.5523 11.6667 12V7C11.6667 6.44772 12.1145 6 12.6667 6Z"
        fill="#FFB020"
      />
    </SvgIcon>
  );
}
