import { SvgIcon } from "@mui/material";
import type { ComponentProps } from "react";

export function JCBCardIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      width="35"
      height="24"
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="34" height="23" rx="2.5" fill="white" />
      <rect x="0.5" y="0.5" width="34" height="23" rx="2.5" stroke="#E5E7EB" />
      <path
        d="M22.3181 12.2873C22.8318 12.2985 23.3484 12.2646 23.86 12.3049C24.3781 12.4016 24.503 13.186 24.0427 13.4428C23.7286 13.6121 23.3555 13.5059 23.015 13.5357H22.3181V12.2873ZM24.157 10.8743C24.2711 11.2771 23.8828 11.6388 23.4948 11.5834H22.3181C22.3263 11.2035 22.302 10.7911 22.33 10.4313C22.8014 10.4445 23.2772 10.4042 23.7459 10.4524C23.9472 10.503 24.1158 10.6685 24.157 10.8743ZM26.989 4.90048C27.0108 5.66978 26.9921 6.47974 26.9984 7.26465C26.997 10.4558 27.0015 13.6469 26.9959 16.8382C26.9752 18.0341 25.9153 19.0733 24.7277 19.0972C23.5388 19.102 22.3498 19.0979 21.1608 19.0993V14.2749C22.4563 14.2682 23.7524 14.2885 25.0474 14.2648C25.6481 14.2271 26.3061 13.8307 26.334 13.1696C26.4048 12.5057 25.7788 12.0465 25.1845 11.974C24.9561 11.968 24.9627 11.9074 25.1845 11.8809C25.7513 11.7584 26.1964 11.1717 26.0297 10.5842C25.8876 9.96624 25.2045 9.72707 24.6364 9.72825C23.4781 9.72038 22.3195 9.72715 21.1611 9.72487C21.1686 8.82422 21.1455 7.92261 21.1735 7.02262C21.2653 5.84825 22.3519 4.87978 23.5229 4.90079C24.6784 4.90061 25.8337 4.90061 26.989 4.90057V4.90048Z"
        fill="url(#paint0_linear_221_7472)"
      />
      <path
        d="M8.07085 7.14447C8.10043 5.95041 9.16487 4.91973 10.351 4.90228C11.5355 4.89863 12.7201 4.90175 13.9045 4.9007C13.9013 8.89578 13.9109 12.8913 13.8996 16.8862C13.854 18.0657 12.8012 19.0768 11.6279 19.0976C10.4413 19.1019 9.25445 19.0983 8.06773 19.0994V14.1125C9.22043 14.3848 10.4292 14.5007 11.6052 14.3201C12.3081 14.207 13.0772 13.8618 13.3152 13.1325C13.4903 12.5087 13.3917 11.8522 13.4178 11.212V9.72509H11.3827C11.3735 10.7085 11.4014 11.6936 11.3679 12.6757C11.3131 13.2795 10.7152 13.663 10.1458 13.6425C9.43969 13.65 8.04039 13.1308 8.04039 13.1308C8.03687 11.2884 8.06087 8.98097 8.07085 7.14465V7.14447Z"
        fill="url(#paint1_linear_221_7472)"
      />
      <path
        d="M14.6631 10.3246C14.5559 10.3473 14.6415 9.95972 14.6141 9.81268C14.6214 8.88298 14.5989 7.95227 14.6266 7.02322C14.7181 5.84388 15.813 4.87299 16.9888 4.9006H20.4512C20.4479 8.89568 20.4576 12.8912 20.4463 16.8861C20.4006 18.0656 19.3477 19.0766 18.1745 19.0975C16.9878 19.102 15.8009 19.0982 14.6141 19.0994V13.6355C15.4246 14.3005 16.5262 14.404 17.536 14.4058C18.2973 14.4055 19.0541 14.2882 19.7934 14.1126V13.1116C18.9602 13.5268 17.9809 13.7906 17.0573 13.552C16.4131 13.3917 15.9454 12.7691 15.9559 12.1042C15.8812 11.4128 16.2865 10.6828 16.9659 10.4773C17.8095 10.2132 18.7289 10.4152 19.5197 10.7585C19.6891 10.8472 19.8611 10.9573 19.7933 10.6741V9.88728C18.4708 9.57263 17.0634 9.45685 15.7348 9.79914C15.3502 9.90763 14.9755 10.0722 14.6631 10.3247V10.3246Z"
        fill="url(#paint2_linear_221_7472)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_221_7472"
          x1="21.121"
          y1="11.6963"
          x2="26.9616"
          y2="11.6963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58B03A" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_221_7472"
          x1="7.94354"
          y1="12.136"
          x2="13.8088"
          y2="12.136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0F6EB6" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_221_7472"
          x1="14.6055"
          y1="11.834"
          x2="20.4501"
          y2="11.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE0D3D" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
