import { SvgIcon, type SvgIconProps } from "@mui/material";

export function PaymentBankIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33325 11V15H7.33325V11H9.33325ZM5.33325 9H7.33325H9.33325H11.3333V11V15V17H9.33325H7.33325H5.33325V15V11V9ZM17.3333 11V15H15.3333V11H17.3333ZM13.3333 9H15.3333H17.3333H19.3333V11V15V17H17.3333H15.3333H13.3333V15V11V9Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 17H6.33325C5.78097 17 5.33325 17.4477 5.33325 18C5.33325 18.5523 5.78097 19 6.33325 19H18.3333C18.8855 19 19.3333 18.5523 19.3333 18C19.3333 17.4477 18.8855 17 18.3333 17ZM6.33325 15C4.6764 15 3.33325 16.3431 3.33325 18C3.33325 19.6569 4.6764 21 6.33325 21H18.3333C19.9901 21 21.3333 19.6569 21.3333 18C21.3333 16.3431 19.9901 15 18.3333 15H6.33325Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26191 9H18.4046C18.9175 9 19.3333 8.58423 19.3333 8.07135C19.3333 7.67773 19.0851 7.32686 18.714 7.19574L12.6664 5.05906C12.4508 4.9829 12.2157 4.9829 12.0001 5.05906L5.95254 7.19574C5.58141 7.32686 5.33325 7.67773 5.33325 8.07135C5.33325 8.58423 5.74903 9 6.26191 9ZM5.28628 5.30998C4.11584 5.72351 3.33325 6.83 3.33325 8.07135C3.33325 9.6888 4.64446 11 6.26191 11H18.4046C20.022 11 21.3333 9.6888 21.3333 8.07135C21.3333 6.83 20.5507 5.72351 19.3802 5.30998L13.3326 3.1733C12.686 2.94482 11.9805 2.94482 11.3339 3.1733L5.28628 5.30998Z"
        fill="#0CB4E9"
      />
    </SvgIcon>
  );
}
