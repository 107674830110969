import { SvgIcon, type SvgIconProps } from "@mui/material";

export function RemoveCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        d="M19.9999 3.33331C10.7952 3.33331 3.33325 10.7952 3.33325 20C3.33325 29.2047 10.7952 36.6666 19.9999 36.6666C29.2047 36.6666 36.6666 29.2047 36.6666 20C36.6666 10.7952 29.2047 3.33331 19.9999 3.33331Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8214 13.8215C14.4723 13.1706 15.5276 13.1706 16.1784 13.8215L19.9999 17.643L23.8214 13.8215C24.4723 13.1706 25.5276 13.1706 26.1784 13.8215C26.8293 14.4723 26.8293 15.5276 26.1784 16.1785L22.3569 20L26.1784 23.8215C26.8293 24.4723 26.8293 25.5276 26.1784 26.1785C25.5276 26.8294 24.4723 26.8294 23.8214 26.1785L19.9999 22.357L16.1784 26.1785C15.5276 26.8294 14.4723 26.8294 13.8214 26.1785C13.1705 25.5276 13.1705 24.4723 13.8214 23.8215L17.6429 20L13.8214 16.1785C13.1705 15.5276 13.1705 14.4723 13.8214 13.8215Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
