import { SvgIcon, type SvgIconProps } from "@mui/material";

export function HamburgerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5C20.5523 5 21 5.44772 21 6C21 6.51284 20.614 6.93551 20.1166 6.99327L20 7H4C3.44772 7 3 6.55228 3 6C3 5.48716 3.38604 5.06449 3.88338 5.00673L4 5H20ZM20 11C20.5523 11 21 11.4477 21 12C21 12.5128 20.614 12.9355 20.1166 12.9933L20 13H4C3.44772 13 3 12.5523 3 12C3 11.4872 3.38604 11.0645 3.88338 11.0067L4 11H20ZM21 18C21 17.4477 20.5523 17 20 17H4L3.88338 17.0067C3.38604 17.0645 3 17.4872 3 18C3 18.5523 3.44772 19 4 19H20L20.1166 18.9933C20.614 18.9355 21 18.5128 21 18Z"
        fill="#7F8B9B"
      />
    </SvgIcon>
  );
}
