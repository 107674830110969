import { SvgIcon, type SvgIconProps } from "@mui/material";

export function WishlistIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="User Interface / Wishlist" clipPath="url(#clip0_29_5975)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29008 8.25999C6.10008 8.06999 5.84008 7.95999 5.58008 7.95999C5.32008 7.95999 5.06008 8.06999 4.87008 8.25999C4.68008 8.44999 4.58008 8.69999 4.58008 8.95999C4.58008 9.21999 4.68008 9.47999 4.87008 9.66999C5.06008 9.85999 5.31008 9.95999 5.58008 9.95999C5.85008 9.95999 6.10008 9.85999 6.29008 9.66999C6.47008 9.47999 6.58008 9.22999 6.58008 8.95999C6.58008 8.68999 6.47008 8.44999 6.29008 8.25999Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1101 12.19C14.9701 12.05 14.7801 11.97 14.5801 11.97H9.08008C8.88008 11.97 8.69008 12.05 8.55008 12.19C8.41008 12.33 8.33008 12.52 8.33008 12.72C8.33008 12.92 8.41008 13.11 8.55008 13.25C8.69008 13.39 8.88008 13.47 9.08008 13.47H14.5801C14.7801 13.47 14.9701 13.39 15.1101 13.25C15.2501 13.11 15.3301 12.92 15.3301 12.72C15.3301 12.52 15.2501 12.33 15.1101 12.19Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33008 8.95999C8.33008 9.05999 8.35008 9.15999 8.39008 9.24999C8.42008 9.33999 8.48008 9.41999 8.55008 9.49999C8.62008 9.56999 8.70008 9.61999 8.79008 9.65999C8.88008 9.68999 8.98008 9.70999 9.08008 9.70999H12.5801C12.4601 9.56999 12.3601 9.40999 12.2901 9.21999L11.9701 8.20999H9.08008C8.88008 8.20999 8.69008 8.28999 8.55008 8.42999C8.41008 8.57999 8.33008 8.76999 8.33008 8.95999Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5801 15.72H9.08008C8.88008 15.72 8.69008 15.8 8.55008 15.94C8.41008 16.08 8.33008 16.27 8.33008 16.47C8.33008 16.67 8.41008 16.86 8.55008 17C8.69008 17.14 8.88008 17.22 9.08008 17.22H14.5801C14.7801 17.22 14.9701 17.14 15.1101 17C15.2501 16.86 15.3301 16.67 15.3301 16.47C15.3301 16.27 15.2501 16.08 15.1101 15.94C14.9701 15.8 14.7801 15.72 14.5801 15.72Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29008 12.01C6.10008 11.82 5.84008 11.71 5.58008 11.71C5.32008 11.71 5.06008 11.82 4.87008 12.01C4.68008 12.2 4.58008 12.45 4.58008 12.71C4.58008 12.97 4.68008 13.23 4.87008 13.42C5.06008 13.61 5.31008 13.71 5.58008 13.71C5.85008 13.71 6.10008 13.61 6.29008 13.42C6.47008 13.23 6.58008 12.98 6.58008 12.71C6.58008 12.44 6.47008 12.2 6.29008 12.01Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.58008 15.47C5.31008 15.47 5.06008 15.57 4.87008 15.76C4.68008 15.95 4.58008 16.2 4.58008 16.47C4.58008 16.74 4.69008 16.99 4.87008 17.18C5.06008 17.37 5.31008 17.47 5.58008 17.47C5.85008 17.47 6.10008 17.36 6.29008 17.18C6.48008 16.99 6.58008 16.74 6.58008 16.47C6.58008 16.2 6.47008 15.95 6.29008 15.76C6.10008 15.57 5.85008 15.47 5.58008 15.47Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.61 5.26L10.99 5.71C11.52 5.89 11.99 6.22 12.34 6.67C12.54 6.92 12.69 7.21 12.79 7.52L13.24 8.9C13.28 9.01 13.35 9.1 13.44 9.16C13.53 9.22 13.64 9.26 13.75 9.26C13.86 9.26 13.97 9.23 14.06 9.16C14.15 9.1 14.22 9 14.26 8.9L14.71 7.52C14.85 7.1 15.08 6.72 15.4 6.41C15.71 6.1 16.09 5.86 16.51 5.72L17.89 5.27C18 5.23 18.09 5.16 18.15 5.07C18.21 4.98 18.25 4.87 18.25 4.76C18.25 4.65 18.22 4.54 18.15 4.45C18.09 4.36 17.99 4.29 17.89 4.25H17.86L16.48 3.79C16.06 3.65 15.68 3.42 15.37 3.1C15.06 2.79 14.82 2.41 14.68 1.99L14.23 0.61C14.19 0.5 14.12 0.41 14.03 0.35C13.94 0.28 13.83 0.25 13.72 0.25C13.61 0.25 13.5 0.29 13.41 0.35C13.32 0.42 13.25 0.51 13.21 0.61L12.76 1.99V2.02C12.61 2.43 12.38 2.8 12.07 3.1C11.77 3.41 11.39 3.64 10.99 3.78L9.61 4.23C9.5 4.27 9.41 4.34 9.35 4.43C9.29 4.52 9.25 4.63 9.25 4.74C9.25 4.85 9.28 4.96 9.35 5.05C9.42 5.14 9.51 5.21 9.61 5.25V5.26Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.19 9.58C20.15 9.53 20.1 9.49 20.04 9.47H20.02L19.25 9.22C19.02 9.14 18.81 9.01 18.63 8.84C18.46 8.67 18.33 8.46 18.25 8.22L18 7.46C17.98 7.4 17.94 7.35 17.89 7.31C17.84 7.27 17.78 7.25 17.72 7.25C17.66 7.25 17.6 7.27 17.55 7.31C17.5 7.35 17.46 7.4 17.44 7.46L17.19 8.22C17.11 8.45 16.99 8.66 16.82 8.83C16.65 9 16.44 9.13 16.21 9.21L15.44 9.46C15.38 9.48 15.33 9.52 15.29 9.57C15.25 9.62 15.23 9.68 15.23 9.74C15.23 9.8 15.25 9.86 15.29 9.91C15.33 9.96 15.38 10 15.44 10.02L16.21 10.27C16.44 10.35 16.66 10.48 16.83 10.65C17 10.82 17.13 11.04 17.21 11.27L17.46 12.03C17.48 12.09 17.52 12.14 17.57 12.18C17.62 12.22 17.68 12.24 17.74 12.24C17.8 12.24 17.86 12.22 17.91 12.18C17.96 12.14 18 12.09 18.02 12.03L18.27 11.27C18.35 11.04 18.48 10.83 18.65 10.65C18.82 10.47 19.03 10.35 19.27 10.27L20.04 10.02C20.1 10 20.15 9.96 20.19 9.91C20.23 9.86 20.25 9.8 20.25 9.74C20.25 9.68 20.23 9.62 20.19 9.57V9.58Z"
          fill="#7F8B9B"
        />
        <path
          id="Vector_9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 13.25C17.48 13.25 17.22 13.17 17 13.01C16.91 12.95 16.82 12.87 16.75 12.78V14.35C16.75 15.48 16.75 16.28 16.7 16.89C16.65 17.5 16.56 17.85 16.43 18.11C16.14 18.67 15.68 19.13 15.12 19.42C14.85 19.55 14.51 19.65 13.9 19.7C13.28 19.75 12.49 19.75 11.35 19.75H8.15C7.02 19.75 6.23 19.75 5.61 19.7C5 19.65 4.66 19.55 4.39 19.42C3.83 19.13 3.37 18.67 3.08 18.11C2.95 17.85 2.85 17.5 2.81 16.89C2.75 16.28 2.75 15.48 2.75 14.35V11.15C2.75 10.01 2.75 9.22 2.81 8.6C2.85 8 2.95 7.65 3.08 7.39C3.37 6.82 3.83 6.36 4.39 6.07C4.66 5.94 5 5.85 5.61 5.8C6.23 5.75 7.02 5.75 8.15 5.75H8.62C8.62 5.75 8.56 5.68 8.53 5.64C8.35 5.37 8.25 5.07 8.25 4.75C8.25 4.43 8.35 4.12 8.53 3.86C8.56 3.82 8.59 3.78 8.62 3.75H8.11C7.03 3.75 6.15 3.75 5.45 3.81C4.72 3.86 4.08 3.99 3.48 4.29C2.54 4.77 1.78 5.54 1.3 6.48C1 7.07 0.87 7.71 0.81 8.44C0.75 9.15 0.75 10.02 0.75 11.1V14.39C0.75 15.47 0.75 16.35 0.81 17.06C0.87 17.78 1 18.43 1.3 19.02C1.78 19.96 2.54 20.72 3.48 21.2C4.08 21.5 4.72 21.63 5.45 21.69C6.15 21.75 7.03 21.75 8.11 21.75H11.4C12.48 21.75 13.35 21.75 14.06 21.69C14.79 21.63 15.43 21.5 16.02 21.2C16.96 20.72 17.73 19.96 18.21 19.02C18.51 18.43 18.64 17.78 18.7 17.06C18.75 16.35 18.75 15.47 18.75 14.39V12.78C18.68 12.87 18.59 12.95 18.5 13.01C18.28 13.17 18.02 13.25 17.75 13.25Z"
          fill="#7F8B9B"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_5975">
          <rect width="19.5" height="21.5" fill="white" transform="translate(0.75 0.25)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
