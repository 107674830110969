import { SvgIcon } from "@mui/material";
import { type ComponentProps } from "react";

export function MoneyCanceledIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 10C18 14.416 14.416 18 10 18C8.72 18 7.512 17.696 6.432 17.168C6.144 17.024 5.816 16.984 5.52 17.064L3.744 17.544C3.568 17.592 3.376 17.592 3.2 17.544C3.024 17.496 2.864 17.4 2.736 17.28C2.6 17.144 2.512 16.984 2.472 16.816C2.424 16.64 2.424 16.448 2.472 16.272L2.952 14.496C3.032 14.184 2.984 13.856 2.848 13.584C2.288 12.464 2 11.248 2 10.008C2 5.584 5.584 2 10 2C14.416 2 18 5.584 18 10ZM7.472 7.472C7.84 7.104 8.424 7.104 8.792 7.472L10 8.68L11.208 7.472C11.576 7.104 12.16 7.104 12.528 7.472C12.896 7.84 12.896 8.424 12.528 8.792L11.32 10L12.528 11.208C12.896 11.576 12.896 12.16 12.528 12.528C12.16 12.896 11.576 12.896 11.208 12.528L10 11.32L8.792 12.528C8.424 12.896 7.84 12.896 7.472 12.528C7.104 12.16 7.104 11.576 7.472 11.208L8.68 10L7.472 8.792C7.104 8.424 7.104 7.84 7.472 7.472Z"
        fill="#0CB4E9"
      />
    </SvgIcon>
  );
}
