import { SvgIcon } from "@mui/material";
import type { ComponentProps } from "react";

export function BankAccountIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C5.44772 12 5 12.4477 5 13V16C3.89543 16 3 16.8954 3 18V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V18C21 16.8954 20.1046 16 19 16V13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V16H15V13C15 12.4477 14.5523 12 14 12C13.4477 12 13 12.4477 13 13V16H11V13C11 12.4477 10.5523 12 10 12C9.44772 12 9 12.4477 9 13V16H7V13C7 12.4477 6.55228 12 6 12Z"
        fill="#7F8B9B"
      />
      <path
        d="M3 7.42337C3 6.57133 3.5398 5.81284 4.34483 5.53373L11.3448 3.1067C11.7692 2.95955 12.2308 2.95955 12.6552 3.1067L19.6552 5.53373C20.4602 5.81284 21 6.57133 21 7.42337V8C21 9.10457 20.1046 10 19 10H5C3.89543 10 3 9.10457 3 8V7.42337Z"
        fill="#7F8B9B"
      />
    </SvgIcon>
  );
}
