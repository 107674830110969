import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function ExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12.7011L2.5 9.99999C2.5 9.53975 2.8731 9.16666 3.33333 9.16666C3.79357 9.16666 4.16667 9.53975 4.16667 9.99999L4.16667 12.6667C4.16667 13.3805 4.16732 13.8657 4.19796 14.2408C4.22781 14.6061 4.28192 14.793 4.34832 14.9233C4.50811 15.2369 4.76308 15.4919 5.07668 15.6517C5.20702 15.7181 5.39385 15.7722 5.7592 15.802C6.13427 15.8327 6.61952 15.8333 7.33333 15.8333H12.6667C13.3805 15.8333 13.8657 15.8327 14.2408 15.802C14.6062 15.7722 14.793 15.7181 14.9233 15.6517C15.2369 15.4919 15.4919 15.2369 15.6517 14.9233C15.7181 14.793 15.7722 14.6061 15.802 14.2408C15.8327 13.8657 15.8333 13.3805 15.8333 12.6667L15.8333 9.99999C15.8333 9.53975 16.2064 9.16666 16.6667 9.16666C17.1269 9.16666 17.5 9.53975 17.5 9.99999V12.7009C17.5 13.3718 17.5 13.9255 17.4632 14.3765C17.4249 14.8449 17.3428 15.2755 17.1367 15.68C16.8171 16.3072 16.3072 16.8171 15.68 17.1367C15.2755 17.3428 14.8449 17.4249 14.3765 17.4632C13.9255 17.5 13.3719 17.5 12.7011 17.5H7.29892C6.62811 17.5 6.07448 17.5 5.62348 17.4632C5.15506 17.4249 4.72448 17.3428 4.32003 17.1367C3.69283 16.8171 3.18289 16.3072 2.86331 15.68C2.65724 15.2755 2.5751 14.8449 2.53683 14.3765C2.49998 13.9255 2.49999 13.3719 2.5 12.7011Z"
        fill="#31343C"
      />
      <path
        d="M8.08934 6.42259C7.7639 6.74803 7.23626 6.74803 6.91083 6.42259C6.58539 6.09715 6.58539 5.56951 6.91083 5.24408L9.41083 2.74408C9.73626 2.41864 10.2639 2.41864 10.5893 2.74408L13.0893 5.24408C13.4148 5.56951 13.4148 6.09715 13.0893 6.42259C12.7639 6.74803 12.2363 6.74803 11.9108 6.42259L10.8334 5.34518V12.5C10.8334 12.9602 10.4603 13.3333 10.0001 13.3333C9.53984 13.3333 9.16675 12.9602 9.16675 12.5V5.34518L8.08934 6.42259Z"
        fill="#31343C"
      />
    </SvgIcon>
  );
}
