import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function UserTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 19C4.5 16.2386 6.73858 14 9.5 14H15.5C18.2614 14 20.5 16.2386 20.5 19C20.5 20.6569 19.1569 22 17.5 22H7.5C5.84315 22 4.5 20.6569 4.5 19Z"
        fill="currentColor"
      />
      <path
        d="M12.5 2C9.73858 2 7.5 4.23858 7.5 7C7.5 9.76142 9.73858 12 12.5 12C15.2614 12 17.5 9.76142 17.5 7C17.5 4.23858 15.2614 2 12.5 2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
