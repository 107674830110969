import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function CoinTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.25"
        d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 5C11.5 4.44772 11.9477 4 12.5 4C13.0523 4 13.5 4.44772 13.5 5V6C15.1569 6 16.5 7.34315 16.5 9C16.5 9.55228 16.0523 10 15.5 10C14.9477 10 14.5 9.55228 14.5 9C14.5 8.44772 14.0523 8 13.5 8H11.5C10.9477 8 10.5 8.44772 10.5 9V9.55848C10.5 9.98891 10.7754 10.3711 11.1838 10.5072L14.4487 11.5955C15.6737 12.0038 16.5 13.1502 16.5 14.4415V15C16.5 16.6569 15.1569 18 13.5 18V19C13.5 19.5523 13.0523 20 12.5 20C11.9477 20 11.5 19.5523 11.5 19V18C9.84315 18 8.5 16.6569 8.5 15C8.5 14.4477 8.94772 14 9.5 14C10.0523 14 10.5 14.4477 10.5 15C10.5 15.5523 10.9477 16 11.5 16H13.5C14.0523 16 14.5 15.5523 14.5 15V14.4415C14.5 14.0111 14.2246 13.6289 13.8162 13.4928L10.5513 12.4045C9.32629 11.9962 8.5 10.8498 8.5 9.55848V9C8.5 7.34315 9.84315 6 11.5 6V5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
