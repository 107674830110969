import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function FileListTwoTonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.25"
        d="M6.6665 11.3337C6.6665 8.53348 6.6665 7.13335 7.21147 6.06379C7.69084 5.12298 8.45574 4.35807 9.39655 3.87871C10.4661 3.33374 11.8662 3.33374 14.6665 3.33374H24.4574C25.2727 3.33374 25.6803 3.33374 26.0639 3.42584C26.4041 3.5075 26.7292 3.64218 27.0275 3.82494C27.3638 4.03108 27.6521 4.31933 28.2286 4.89584L31.7711 8.43831C32.3476 9.01482 32.6358 9.30307 32.842 9.63946C33.0247 9.9377 33.1594 10.2628 33.2411 10.603C33.3332 10.9866 33.3332 11.3942 33.3332 12.2095V28.6671C33.3332 31.4673 33.3332 32.8675 32.7882 33.937C32.3088 34.8778 31.5439 35.6427 30.6031 36.1221C29.5336 36.6671 28.1334 36.6671 25.3332 36.6671H14.6665C11.8662 36.6671 10.4661 36.6671 9.39655 36.1221C8.45574 35.6427 7.69084 34.8778 7.21147 33.937C6.6665 32.8675 6.6665 31.4673 6.6665 28.6671V11.3337Z"
        fill="currentColor"
      />
      <path
        d="M16.6668 21.6672C16.6668 22.5876 15.9206 23.3338 15.0002 23.3338C14.0797 23.3338 13.3335 22.5876 13.3335 21.6672C13.3335 20.7467 14.0797 20.0005 15.0002 20.0005C15.9206 20.0005 16.6668 20.7467 16.6668 21.6672Z"
        fill="currentColor"
      />
      <path
        d="M21.6668 20.0005C20.7464 20.0005 20.0002 20.7467 20.0002 21.6672C20.0002 22.5876 20.7464 23.3338 21.6668 23.3338H25.0002C25.9206 23.3338 26.6668 22.5876 26.6668 21.6672C26.6668 20.7467 25.9206 20.0005 25.0002 20.0005H21.6668Z"
        fill="currentColor"
      />
      <path
        d="M21.6668 26.6672C20.7464 26.6672 20.0002 27.4133 20.0002 28.3338C20.0002 29.2543 20.7464 30.0005 21.6668 30.0005H25.0002C25.9206 30.0005 26.6668 29.2543 26.6668 28.3338C26.6668 27.4133 25.9206 26.6672 25.0002 26.6672H21.6668Z"
        fill="currentColor"
      />
      <path
        d="M15.0002 30.0005C15.9206 30.0005 16.6668 29.2543 16.6668 28.3338C16.6668 27.4133 15.9206 26.6672 15.0002 26.6672C14.0797 26.6672 13.3335 27.4133 13.3335 28.3338C13.3335 29.2543 14.0797 30.0005 15.0002 30.0005Z"
        fill="currentColor"
      />
      <path
        d="M23.3335 5.69076C23.3335 4.38902 24.3888 3.33374 25.6905 3.33374C26.3156 3.33374 26.9152 3.58207 27.3572 4.0241L32.6431 9.31005C33.0852 9.75208 33.3335 10.3516 33.3335 10.9767C33.3335 12.2785 32.2782 13.3337 30.9765 13.3337H26.6668C24.8259 13.3337 23.3335 11.8414 23.3335 10.0004V5.69076Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
