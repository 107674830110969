import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function UpdatedStatementDescriptorIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.085 14.7522C20.155 14.7522 20.215 14.7522 20.275 14.7522H21.635C21.925 14.7522 22.165 14.5122 22.165 14.2222V9.33224C22.165 8.69224 21.645 8.17224 20.995 8.17224H6.99496C6.35496 8.17224 5.83496 8.69224 5.83496 9.33224V23.9322C5.83496 24.3422 6.25496 24.6222 6.63496 24.4622L8.95496 23.4922C9.20496 23.3922 9.47496 23.3722 9.73496 23.4422C9.73496 23.4422 11.365 23.9122 11.375 23.9122C11.925 24.0722 12.515 24.2222 13.105 24.2222C13.565 24.2322 14.375 24.1722 14.685 23.7722C14.765 23.7022 14.805 23.6022 14.825 23.5022C14.855 23.3422 14.765 23.1522 14.715 23.0122C14.115 21.3122 14.135 19.3922 14.985 17.7722C15.685 16.4222 16.915 15.3922 18.395 15.0022C18.975 14.8522 19.545 14.7722 20.085 14.7622V14.7522ZM12.835 18.6622H10.495C9.85496 18.6622 9.33496 18.1422 9.33496 17.4922C9.33496 16.8422 9.85496 16.3222 10.495 16.3222H12.835C13.475 16.3222 13.995 16.8522 13.995 17.4922C13.995 18.1322 13.475 18.6622 12.835 18.6622ZM12.835 13.9922H10.495C9.85496 13.9922 9.33496 13.4722 9.33496 12.8222C9.33496 12.1722 9.85496 11.6622 10.495 11.6622H12.835C13.475 11.6622 13.995 12.1822 13.995 12.8222C13.995 13.4622 13.475 13.9922 12.835 13.9922ZM17.495 13.9922C16.855 13.9922 16.335 13.4722 16.335 12.8222C16.335 12.1722 16.855 11.6622 17.495 11.6622C18.135 11.6622 18.665 12.1822 18.665 12.8222C18.665 13.4622 18.145 13.9922 17.495 13.9922Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3251 3.49207H4.66512C4.01512 3.49207 3.49512 4.01207 3.49512 4.66207C3.49512 5.31207 4.01512 5.83207 4.66512 5.83207H23.3351C23.9751 5.83207 24.5051 5.31207 24.5051 4.66207C24.5051 4.01207 23.9851 3.49207 23.3351 3.49207H23.3251Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9052 17.5323C22.7052 17.3323 22.4352 17.2323 22.1652 17.2423C21.9152 17.2423 21.6752 17.3423 21.4852 17.5323L17.9252 21.1023C17.8352 21.1923 17.7552 21.3023 17.7052 21.4223L16.8252 23.5423C16.7852 23.6523 16.7752 23.7523 16.7952 23.8523C16.8652 24.1323 17.1652 24.3323 17.4752 24.2023L19.1152 23.5123L19.6052 23.3123C19.7252 23.2623 19.8352 23.1923 19.9252 23.1023L22.1652 20.8523L23.4852 19.5323C23.8752 19.1423 23.8752 18.5123 23.4852 18.1223L22.9052 17.5323Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
