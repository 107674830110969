import { SvgIcon, type SvgIconProps } from "@mui/material";

export function EcommerceGiftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g id="E-commerce / Gift">
        <path
          id="Style"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5.49997C20 3.56697 18.433 1.99997 16.5 1.99997L16.2914 2.00183C14.5606 2.06311 13.0329 3.26341 12 5.17697L11.8644 4.93412C10.7953 3.09171 9.2384 1.96953 7.48258 2.00012C5.567 1.99997 4 3.56697 4 5.49997C4 6.03677 4.12085 6.54534 4.33682 6.99997H4C2.89543 6.99997 2 7.8954 2 8.99997V11C2 12.1045 2.89543 13 4 13V19L4.00509 19.1762C4.09634 20.751 5.40232 22 7 22H12H17L17.1763 21.9949C18.7511 21.9036 20 20.5977 20 19V13C21.1046 13 22 12.1045 22 11V8.99997C22 7.8954 21.1046 6.99997 20 6.99997H19.6632C19.8792 6.54534 20 6.03677 20 5.49997ZM16.5 6.99997C17.3284 6.99997 18 6.3284 18 5.49997C18 4.67154 17.3284 3.99997 16.4826 3.99982C15.3028 3.97926 14.0978 5.09604 13.357 6.99997H16.5ZM10.6429 6.99997C9.90173 5.09605 8.69426 3.97929 7.5 3.99997C6.67157 3.99997 6 4.67154 6 5.49997C6 6.27967 6.59489 6.92042 7.35554 6.9931L7.5 6.99997H10.6429ZM7.5 8.99997H4V11H5H11V8.99997H7.5ZM6 19V13H11V20H7L6.88338 19.9932C6.38604 19.9355 6 19.5128 6 19ZM17 20H13V13H18V19L17.9933 19.1166C17.9355 19.6139 17.5128 20 17 20ZM20 11H19H13V8.99997H16.5H20V11Z"
          fill="currentcolor"
        />
      </g>
    </SvgIcon>
  );
}
