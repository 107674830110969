import { SvgIcon, type SvgIconProps } from "@mui/material";

export function LeaderboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M15.5 22.9989H8.50002M15.5 22.9989V13.1989C15.5 13.0133 15.4263 12.8352 15.295 12.7039C15.1637 12.5727 14.9857 12.4989 14.8 12.4989H9.20002C9.01437 12.4989 8.83632 12.5727 8.70504 12.7039C8.57377 12.8352 8.50002 13.0133 8.50002 13.1989V22.9989M15.5 22.9989H21.8001C21.9857 22.9989 22.1638 22.9252 22.295 22.7939C22.4263 22.6626 22.5001 22.4846 22.5001 22.2989V19.6156C22.5001 19.4299 22.4263 19.2519 22.295 19.1206C22.1638 18.9893 21.9857 18.9156 21.8001 18.9156H16.2C16.0144 18.9156 15.8363 18.9893 15.7051 19.1206C15.5738 19.2519 15.5 19.4299 15.5 19.6156V22.9989ZM8.50002 22.9989V17.2823C8.50002 17.0966 8.42627 16.9186 8.29499 16.7873C8.16372 16.656 7.98567 16.5822 7.80002 16.5822H2.2C2.01435 16.5822 1.8363 16.656 1.70503 16.7873C1.57375 16.9186 1.5 17.0966 1.5 17.2823V22.2989C1.5 22.4846 1.57375 22.6626 1.70503 22.7939C1.8363 22.9252 2.01435 22.9989 2.2 22.9989H8.50002ZM10.607 4.46405L11.6675 2.21588C11.6962 2.15161 11.7428 2.09702 11.8018 2.0587C11.8608 2.02039 11.9297 2 12 2C12.0704 2 12.1392 2.02039 12.1982 2.0587C12.2573 2.09702 12.3039 2.15161 12.3325 2.21588L13.3942 4.46405L15.7649 4.82689C16.0694 4.87355 16.1907 5.26555 15.9702 5.48955L14.2552 7.23956L14.66 9.71057C14.7114 10.0279 14.394 10.2706 14.121 10.1201L12 8.9534L9.87902 10.1201C9.60719 10.2694 9.28869 10.0279 9.34002 9.71057L9.74485 7.23956L8.02985 5.48955C7.80818 5.26555 7.93068 4.87355 8.23402 4.82689L10.607 4.46405Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.10001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
