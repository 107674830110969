import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CheckCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M20.5007 3.3335C11.2959 3.3335 3.83398 10.7954 3.83398 20.0002C3.83398 29.2049 11.2959 36.6668 20.5007 36.6668C29.7054 36.6668 37.1673 29.2049 37.1673 20.0002C37.1673 10.7954 29.7054 3.3335 20.5007 3.3335Z"
        fill={props.fill || "#0CB4E9"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6792 15.4882C27.33 16.139 27.33 17.1943 26.6792 17.8452L20.0125 24.5118C19.3616 25.1627 18.3063 25.1627 17.6555 24.5118L14.3221 21.1785C13.6713 20.5276 13.6713 19.4724 14.3221 18.8215C14.973 18.1706 16.0283 18.1706 16.6792 18.8215L18.834 20.9763L24.3221 15.4882C24.973 14.8373 26.0283 14.8373 26.6792 15.4882Z"
        fill={props.path}
      />
    </SvgIcon>
  );
}
