import { SvgIcon, type SvgIconProps } from "@mui/material";

export function ExclamationCircleFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33337 14C2.33337 7.55672 7.55672 2.33337 14 2.33337C20.4434 2.33337 25.6667 7.55672 25.6667 14C25.6667 20.4434 20.4434 25.6667 14 25.6667C7.55672 25.6667 2.33337 20.4434 2.33337 14ZM14 8.16671C14.6444 8.16671 15.1667 8.68904 15.1667 9.33337V14C15.1667 14.6444 14.6444 15.1667 14 15.1667C13.3557 15.1667 12.8334 14.6444 12.8334 14V9.33337C12.8334 8.68904 13.3557 8.16671 14 8.16671ZM12.8334 18.6667C12.8334 18.0224 13.3557 17.5 14 17.5C14.6444 17.5 15.1667 18.0224 15.1667 18.6667C15.1667 19.311 14.6444 19.8334 14 19.8334C13.3557 19.8334 12.8334 19.311 12.8334 18.6667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
